<template>
    <div class="wholeMode" :styles="[props.customModeStyles]">
      <div class="blurView" @click="$emit('close')"></div>
      <div class="scrollWrapper">
        <!-- <p @click="$emit('close')">Close Mode</p> -->
        <ScrollView height="">
          <div class="innerWrapper">
            <Box class="innerBox" :customBoxStyles="['background-color: none;', props.customBoxStyles]">
              <slot></slot>
            </Box>
          </div>
        </ScrollView>
      </div> 
    </div>
</template>
<script setup>
import Box from './Box.vue';
import ScrollView from './ScrollView.vue';
import { useEmidyStore } from '../../store/emidystore';
import { onBeforeUnmount, onMounted, defineProps } from 'vue'

const props = defineProps(["customModeStyles", "customBoxStyles"]);
const store = useEmidyStore();


// HOW TO EMIT
// const emit = defineEmits(['customChange'])

// const handleChange = (event) => {
//   emit('customChange', event.target.value.toUpperCase())
// }


onMounted(() => {
  // document.querySelectorAll("*").style.overflow = "hidden !important"
  // document.querySelector("html").style.overflow = "hidden !important"
})

onBeforeUnmount(() => {
    // document.querySelectorAll("*").style.overflow = "visible"
    // document.querySelector("html").style.overflow = "visible"

})
</script>
<style scoped>
.wholeMode{
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
}
.blurView{
  position: absolute;
  backdrop-filter: blur(5px);
  background-color: rgba(0,0,0,.3);
  -webkit-backdrop-filter: blur(15px);
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 4;
}
.scrollWrapper{
  position: absolute;
  margin-top: 5vh;
  max-height: 85vh;
  width: 90vw;
  margin-left: 5vw;
  display: flex;
  background-color: rgba(0,0,0,.7);
  z-index: 5;
  border-radius: 15px;
}
.innerBox, .innerWrapper{
  width: 90vw;
  padding: 0;
  margin: 0;
}
</style>
