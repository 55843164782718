const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

module.exports = {
  accent1: '#ab93be',
  accent1O: "rgba(171,147,190, .2)",
  accent2: '#2f95dc',
  accent3: '#99cfe0',
  badColor: "red",
  poorColor: "#e69500",
  goodColor: "#ffca00",
  greatColor: "green",
  light: {
    text: '#000',
    background: '#f2f2f2',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    // cardBackground: '#e8effc', // too light
    cardBackground: '#e8eafc',
    backgroundSecondary: "rgba(0, 0, 0, .2)",
    accent: '#ab93be'
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
    cardBackground: '#292e38',
    backgroundSecondary: "#696C73",
    accent: '#ab93be'
  }
};
