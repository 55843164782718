<template>
<div>
  <TopBar></TopBar>

  <p class="topicText" :style="`color: ${colorScheme.text};`">Lessons are your daily experiences. Track your progress towards your emotional and mental growth!</p>
  <div class="horLine"></div>

  <select v-if="!fromTopic" v-model="topicID" class="topicSelect">
    <option value="" selected disabled class="topicOptions">--Select Topic (Required)--</option>
    <option v-for="t in userTopics" :key="t._id" :value="t._id" class="topicOptions">{{truncate(t.title, 20)}}</option>
  </select>

  <p v-if="fromTopic" class="chosenTopic" :style="`color: ${colorScheme.text};`">Topic: {{topicName ? topicName : 'Loading...'}}</p>
  <br/>
  <input v-model="title" class="topicInput required" placeholder="* What did you experience today?"/>
  <br/>
  <input v-model="emotion" class="topicInput required" placeholder="* How did you do today? 1-10"/>
  <br/>
  <textarea v-model="description" class="topicInput" rows="5" placeholder="Describe your experience:"></textarea>
  <br/>
  <input v-model="reminder" class="topicInput" placeholder="Reminder for your future self:"/>
  <br/>

  <p class="createBtn" @click="createLesson" :style="`background-color: ${accent2};`">Create Lesson</p>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
</div>
</template>
<script setup>
import TopBar from "../components/TopBar.vue"
import Box from "../components/Box.vue"
import api from "../../actors/api";
import { onBeforeMount, ref } from "vue";
import { useEmidyStore } from '../../store/emidystore';
import { truncate } from "../../helpers/Functions";
import { accent1, accent2, light, dark } from "../../helpers/Colors";
import router from "@/router";
const route = useRoute();
import { useRoute } from 'vue-router';

const store = useEmidyStore();

let colorScheme = store.dark ? dark : light;

let fromTopic = ref(false);
let topicName = ref(null);

let topicID = ref("");
let title = ref(null);
let emotion = ref(null);
let description = ref(null);
let reminder = ref(null);

let userTopics = ref(null);

onBeforeMount(() => {
  api.get("emidy/topics", store.token)
  .then(res => {
    if(res.status >= 400){
      console.log("Error getting topics")
    }else{
      res.json().then(data => {
        if(data.length == 0){
          userTopics.value = [{title: "No Topics Found", _id: ""}];
          alert("Please create a Topic first")
          router.back();
        }else{
          userTopics.value = data;

          if(route.query.topicID){
            let top = data.find((t) => {return t._id == route.query.topicID});

            if(top){
              topicName.value = top.title;
            }
          }
        }
      })
    }
  })

  if(route.query.topicID){
    fromTopic.value = true;
    topicID.value = route.query.topicID;
  }
})

let createLesson = () => {
  if(
    !topicID.value ||
    !title.value ||
    !emotion.value
  ){
    alert("Fill In Required Fields");
  }else{
    if(!description.value){
      description.value = "";
    }
    if(!reminder.value){
      reminder.value = "";
    }


    api.post("emidy/lesson", {
      topicID: topicID.value, 
      title: title.value,
      description: description.value,
      emotion: parseInt(emotion.value),
      reminder: reminder.value
    }, store.token)
    .then((res) => {
      if(res.status == 200){
        store.toggleForceUpdate();
        router.back();
      }else{
        alert("Sorry, Try again later :(");
      }
    })
  }
}

</script>
<style scoped>
.topicSelect{
  width: 80%;
  margin: 20px;
  height: 40px;
  color: black;
  background-color: white;
}
.topicText{
  text-align: left;
  font-weight: bold;
  font-size: 1.1rem;
  padding: 2px 15px;
}
/* .textInputWrap{
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
  padding-left: 20px;
  padding-top: 20px;
} */
/* .inputLabel{
  color: black;
  width: 100%;
  text-align: left;
  font-weight: bold;
  margin: 0;
  padding: 0;
} */
.topicInput{
  width: 90%;
  padding: 10px;
  margin-bottom: 10px;
}

.horLine{
  width: 80%;
  margin-left: 10%;
  border-bottom: 1px solid #777;
}

.createBtn{
  width: 80%;
  margin-left: 10%;
  padding: 7px;
  color: #f2f2f2;
  font-weight: bold;
  border-radius: 7px;
  margin-top: 20px;
  border: 1px solid grey;
}
.chosenTopic{
  font-weight: bold;
  font-size: 1.1rem;
}

.req{
  color: red !important;
}

.required::-webkit-input-placeholder {
  background: -webkit-linear-gradient(left,red 1%, #555 2%, #555 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>