import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

const pinia = createPinia();
const app = createApp(App);


app.use(VCalendar, {});



app.use(pinia);
app.use(router);

app.mount('#app');
