<template>
<div>
  <h3 class="featuredTitle" :style="`color: ${colorScheme.text};`">Features Articles</h3>

  <div class="bannerWrap" @click="router.push({path: '/infoemidy'})">
    <img src="../assets/images/banner.jpg" alt="banner" class="bannerImg"/>
    <p class="bannerText">What is Emidy?</p>
  </div>
  <div class="bannerWrap" @click="router.push({path: '/infosolve'})">
    <img src="../assets/images/banner.jpg" alt="banner" class="bannerImg"/>
    <p class="bannerText">What does Emidy solve?</p>
  </div>
  <div class="bannerWrap" @click="router.push({path: '/infouse'})">
    <img src="../assets/images/banner.jpg" alt="banner" class="bannerImg"/>
    <p class="bannerText">How should I use Emidy?</p>
  </div>

  <h3 class="featuredTitle" :style="`color: ${colorScheme.text};`">Personalized Articles Coming Soon</h3>
</div>
</template>
<script setup>
import Box from "./Box.vue";
import { light, dark } from "../../helpers/Colors";
import { useEmidyStore } from '../../store/emidystore';
import router from "@/router";

const store = useEmidyStore();

let colorScheme = store.dark ? dark : light;
</script>
<style scoped>
.featuredTitle{
  font-size: 1.7rem;
  letter-spacing: 2px;
  margin-top: 75px;
}
.bannerWrap{
  position: relative;
  margin-bottom: 20px;
}
.bannerImg{
  width: 90%;
  border-radius: 20px;
}
.bannerText{
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 0;
  margin: 0;

}
</style>