<template>
<div class="allNavWrap" @click="$emit('close')">
    <div class="topNavSection" :style="`background-color: ${accent1};`">
        <img alt="Emidy Logo" src="../assets/logo.png" style="width: 75px;">
        <!-- <img alt="Close Menu" src="../assets/icons/close-menu.svg" class="closeMenuBtn" @click="$emit('close')" :style="svgColorScheme"/> -->
    </div>
    <ScrollView :style="`background-color: ${accent1};`" class="innerNavWrap">
        <br/>
        <div class="navWrap" @click="goto('dash')">
            <div class="navIconWrap">
                <img src="../assets/icons/tiles.svg" alt="Dashboard" class="navSvg" :class="activateTab == 0 ? 'active' : ''" :style="svgColorScheme"/>
            </div>
            <p class="navText" :class="activateTab == 0 ? 'active' : ''">Dashboard</p>
        </div>
        <div class="line"></div>
        <div class="navWrap" @click="goto('topics')">
            <div class="navIconWrap">
                <img src="../assets/icons/widget.svg" alt="Topics" class="navSvg"  :class="activateTab == 1 ? 'active' : ''" :style="svgColorScheme"/>
            </div>
            <p class="navText" :class="activateTab == 1 ? 'active' : ''">Topics</p>
        </div>
        <div class="line"></div>
        <div class="navWrap" @click="goto('lessons')">
            <div class="navIconWrap">
                <img src="../assets/icons/star.svg" alt="Lessons" class="navSvg"  :class="activateTab == 2 ? 'active' : ''" :style="svgColorScheme"/>
            </div>
            <p class="navText" :class="activateTab == 2 ? 'active' : ''">Lessons</p>
        </div>
        <div class="line"></div>
        <div class="navWrap" @click="goto('resources')">
            <div class="navIconWrap">
                <img src="../assets/icons/book.svg" alt="Resources" class="navSvg"  :class="activateTab == 3 ? 'active' : ''" :style="svgColorScheme"/>
            </div>
            <p class="navText" :class="activateTab == 3 ? 'active' : ''">Resources</p>
        </div>
        <div class="line"></div>
        <div class="navWrap" @click="goto('feedback')">
            <div class="navIconWrap">
                <img src="../assets/icons/chat.svg" alt="Feedback" class="navSvg"  :class="activateTab == 4 ? 'active' : ''" :style="svgColorScheme"/>
            </div>
            <p class="navText" :class="activateTab == 4 ? 'active' : ''">Feedback</p>
        </div>
        <div class="line"></div>


    </ScrollView>
</div>
</template>
<script setup>
import { useEmidyStore } from '../../store/emidystore';
import { onBeforeUnmount, onMounted, ref, watch, defineEmits } from 'vue'
import router from '@/router';
import { useRoute } from 'vue-router';
import { light, dark, accent1 } from "../../helpers/Colors";
import ScrollView from "@/components/ScrollView.vue";

const store = useEmidyStore();
const route = useRoute();
const emit = defineEmits(['close'])

let colorScheme = store.dark ? dark : light;
let svgColorScheme = store.dark ? 'filter: invert(100%) sepia(1%) saturate(2656%) hue-rotate(288deg) brightness(115%) contrast(90%);' : 'filter: invert(0%) sepia(6%) saturate(7500%) hue-rotate(12deg) brightness(104%) contrast(106%);';

const activateTab = ref(null);

watch(route, () => {activeTab(route.path)});

onMounted(() => {
  activeTab(route.path);
})


let activeTab = (path) => {
  if(path.includes("dash")){
    activateTab.value = 0;
  }else if(path.includes("topics")){
    activateTab.value = 1;
  }else if(path.includes("lessons")){
    activateTab.value = 2;
  }else if(path.includes("resources")){
    activateTab.value = 3;
  }else if(path.includes("feedback")){
    activateTab.value = 4;
  }
}


let goto = (path) => {
  emit("close");
  router.replace(`/${path}`);
}


</script>
<style>

.allNavWrap{
  position: fixed;
  background-color: rgba(0,0,0,.7);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
  padding-top: 5px;
  z-index: 9;
}
.topNavSection{
    width: 70vw;
    height: 8vh;
}
.closeMenuBtn{
    width: 35px;
    position: absolute;
    left: 55%;
    top: 3%;
}
.innerNavWrap{
    height: 92vh;
}
.navWrap{
  display: flex;
  width: 70vw;
  flex-direction: row;
  align-items: center;
  justify-content: start; 
  padding: 10px;
}
.navWrap:active{
  opacity: .5;
}
.navWrap p{
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  color: #f2f2f2;
  font-weight: bold;
  padding-bottom: 3px;
}
.navIconWrap{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
}
.navSvg{
  /* width: 18px; */
  height: 20px;
  margin-bottom: 3px;
  /* filter: invert(45%) sepia(88%) saturate(0%) hue-rotate(233deg) brightness(88%) contrast(101%); */
  /*
    SVG Filter Generator
    https://codepen.io/sosuke/pen/Pjoqqp

    Other colors:
    Black - filter: invert(0%) sepia(6%) saturate(7500%) hue-rotate(12deg) brightness(104%) contrast(106%);
    Grey - filter: invert(45%) sepia(88%) saturate(0%) hue-rotate(233deg) brightness(88%) contrast(101%);
    Main - filter: invert(68%) sepia(23%) saturate(403%) hue-rotate(230deg) brightness(88%) contrast(85%);
    Secondary - filter: invert(60%) sepia(33%) saturate(5199%) hue-rotate(179deg) brightness(92%) contrast(87%);
    f2 -    filter: invert(100%) sepia(1%) saturate(2656%) hue-rotate(288deg) brightness(115%) contrast(90%);
  */
}
.active{
  filter: invert(60%) sepia(33%) saturate(5199%) hue-rotate(179deg) brightness(92%) contrast(87%) !important;
  /* color: #2f95dc; */
}
.line{
  height: 1px;
  width: 90%;
  margin-left: 5%;
  border-bottom: 1px solid rgba(255, 255, 255, .1);
}
</style>