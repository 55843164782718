<template>
  <div>
    <TopBar></TopBar>

    <FeaturedArticles></FeaturedArticles>
   
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
  </div>
</template>
<script setup>
import TopBar from "../components/TopBar.vue"
import FeaturedArticles from "@/components/FeaturedArticles.vue";
import { onMounted } from "vue";

onMounted(() => {
  window.scrollTo(0, 0);
})
</script>
<style scoped>

</style>