<template>
    <div class="box" :style="[background, props.customBoxStyles]">
      <slot></slot>
    </div>
</template>
<script setup>
// style={[ {backgroundColor: colorScheme.cardBackground}, styles.box, props.showShadow ? styles.shadow : '', props.style]}
import { ref , onMounted, defineProps } from "vue";
import { useEmidyStore } from '../../store/emidystore';
import { accent1, light, dark } from "../../helpers/Colors";

const store = useEmidyStore();
let colorScheme = store.dark ? dark : light;

const props = defineProps(["customBoxStyles"]);

const background = store.dark ? `background-color: ${dark.cardBackground}` : `background-color: ${light.cardBackground}`;

onMounted(() => {
})



</script>
<style scoped>
  .box{
    border-radius: 8px;
    margin: 16px;
    padding: 16px;
    width: '100%';
  }
</style>

