<template>
  <!-- <TopBar :title="thisTopicTitle" :color="thisTopicColor" textColor="#f2f2f2" svgColor="filter: invert(100%) sepia(1%) saturate(2656%) hue-rotate(288deg) brightness(115%) contrast(90%);" @options="confirmEdit = !confirmEdit"></TopBar> -->
  <TopBar :title="`Lessons: ${dateFormat(date)}`"></TopBar>
    <div v-if="hasLessons && !loading" >
      <LessonBox v-for="l in lessons" :key="l.id" :l="l"></LessonBox>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
    <div v-if="!hasLessons && !loading" >
      <p class="info" :style="`color: ${colorScheme.text};`">No Lessons This Day</p>
    </div>
    <div v-if="loading">
      <p class="info" :style="`color: ${colorScheme.text};`">Loading...</p>
    </div>
</template>
<script setup>
import { computed, onBeforeMount, ref } from "vue"
import TopBar from "../components/TopBar.vue"
import LessonBox from "../components/LessonBox.vue"
import { dateFormat } from "../../helpers/Functions";
import { useRoute } from 'vue-router';
import { useEmidyStore } from '../../store/emidystore';
import api from "../../actors/api";
import { light, dark } from "../../helpers/Colors";

const store = useEmidyStore();
const route = useRoute();

let colorScheme = store.dark ? dark : light;

let date = ref(null);
let topicID = ref(null);

let lessons = ref(null);
let loading = ref(true);


onBeforeMount(() => {
  date.value = route.query.date.split("T")[0];
  topicID.value = route.query.topicID;


  if(route.query.topicID){
    api.get(`emidy/lessons/${topicID.value}/${date.value}`, store.token)
    .then(res => {
      if(res.status >= 400){
        
      }else{
        res.json().then((data) => {
          if(data.length > 0){
            lessons.value = data;
          }
            loading.value = false;
        })
      }
    })
  }else{
    api.get(`emidy/lessons/${date.value}`, store.token)
    .then(res => {
      if(res.status >= 400){
      }else{
        res.json().then((data) => {
          if(data.length > 0){
            lessons.value = data;
          }
            loading.value = false;
        })
      }
    })
  }
})


let hasLessons = computed(() => {
  if(lessons.value){

    if(lessons.value.length > 0){
      return true;
    }
  }
  return false;
})

</script>
<style scoped>
.info{
  font-size: 18px;
  font-weight: bold;
}
</style>