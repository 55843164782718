<template>
<div>
  <TopBar title="What Is Emidy?"></TopBar>
  <Box>
  <div class="infoHeader">
    <p>Welcome To Emidy!</p>
  </div>

  <div :style="`color: ${colorScheme.text};`" class="textWrap">
        <p class="text big">Emidy is a new way to achieve your mental and emotional goals!</p>
        <p class="text sma">The main purpose of Emidy is to help you overcome your blockades and give you insight on how you personally learn and develop.</p>
        <p class="text sma">So many of us struggle with comprehending our emotions and achieving our dreams. We live our lives unaware of how we actually react to the world around us, and what we need to do in order to grow.</p>
        <p class="text med">Trying to blindly navigate our minds and emotions feels like an impossible task. There's an overwhelming number of ways on how to improve oneself, but when they don't stick, it's easy to get discouraged.</p>
        <p class="text big">What kind of life awaits those who don't learn to manage their minds intimately, and how happy would people be once they learn to achieve their goals?</p>
        <p class="text big">Our mission is to make understanding oneself simple. Emidy is a system where you build your own personal trainer for your mind.</p>
        <p class="text med">No one knows you better than yourself, so Emidy guides you to take note of what actually works for you.</p>
        <p class="text sma">Emidy works by splitting your focus into specific "Topics". These Topics are a collection of your daily experiences.</p>
        <p class="text sma">This first step is essential when trying to improve an aspect of your life. The more focused the Topic, the clearer it is to see your progress.</p>
        <p class="text med">By tracking your experiences daily and reflecting on them, they turn into "Lessons". </p>
        <p class="text med">These Lessons are at the core of how you perceive your life. With a simple score, you can track how well you achieved your goal that day. No matter how bad or well you actually do, each step guides you in the right direction.</p>
        <p class="text big">We appreciate your time and focus, and as we continuously develop Emidy, we will bring new ways to analyze your lessons, reflect on them to gain deeper insight, and help you learn who we are in order to achieve our dreams.</p>
  </div>
  </Box>
  <br/>
  <br/>
  <br/>
</div>
</template>
<script setup>
import Box from "../components/Box.vue";
import TopBar from "../components/TopBar.vue"
import { light, dark } from "../../helpers/Colors";
import { useEmidyStore } from '../../store/emidystore';
import { onMounted } from "vue";


const store = useEmidyStore();
let colorScheme = store.dark ? dark : light;


onMounted(() => {
  window.scrollTo(0, 0);
})


</script>
<style scoped>
.infoHeader{
  background-image: url("../assets/images/banner.jpg");
  color: white;
  padding: 1px;
  border-radius: 10px;
  font-weight: bold;
}
.textWrap{
  text-align: left;
  padding: 15px;
  line-height: 30px;
  font-weight: 500;
}
.big{
  font-size: 1.1rem;
  font-weight: 600;
}
.med{
  font-size: 1.05rem;
}
.sma{
  font-size: 1rem;
}
</style>