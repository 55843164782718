<template>
  <TopBar :title="truncate(title, 25)" :color="color" textColor="#f2f2f2" svgColor="filter: invert(100%) sepia(1%) saturate(2656%) hue-rotate(288deg) brightness(115%) contrast(90%);" @options="editing = !editing"></TopBar>

  <Mode v-if="editing" @close="cancelEdit" >
    <div class="wholeEdit">
      <div class="textEditWrap">
        <label class="editLabel"> Change Topic</label>
      </div>
      <div>
        <select v-model="edittopicID" class="topicSelect">
          <option value="" selected disabled class="topicOptions">--Select Topic--</option>
          <option v-for="t in userTopics" :key="t._id" :value="t._id" class="topicOptions">{{truncate(t.title, 20)}}</option>
        </select>
      </div>
      <div class="textEditWrap">
        <label class="editLabel"> Edit Title</label>
      </div>
      <input v-model="edittitle" class="editInput"/>
      <br/>
      <div class="textEditWrap">
        <label class="editLabel"> Edit Description</label>
      </div>
      <textarea v-model="editdescription" class="editInput" rows="5"></textarea>
      <br/>
      <div class="textEditWrap">
        <label class="editLabel"> Edit Emotion</label>
      </div>
      <input type="number" @input="emotionChange" v-model="pendingeditemotion" class="editInput" min="1" max="10"/>
      <br/>
      <div class="textEditWrap">
        <label class="editLabel"> Edit Reminder</label>
      </div>
      <input v-model="editreminder" class="editInput"/>
      <div class="editBtnWrap">
        <p class="subBtn ornBtn" @click="cancelEdit">Cancel</p>
        <p class="subBtn greenBtn" @click="saveEditedLesson">Save</p>
      </div>
      <div class="horLine"></div>
      <p class="subBtn redBtn" @click="beginDelete">Delete Lesson</p>
    </div>
  </Mode>
  <Mode v-if="deleteConfirm" @close="cancelEdit">
    <p class="reallyText">Really Delete Lesson?</p>
    <div class="editBtnWrap">
      <p class="subBtn redBtn" @click="deleteLesson">Yes</p>
      <p class="subBtn greenBtn" @click="cancelEdit">No</p>
    </div>
  </Mode>


  <Box class="lessonSingleWrap" :style="`color: ${colorScheme.text};`">
    <p class="title">{{title}}</p>
    <p class="created">{{dateFormat(created)}}</p>
    <p class="lessonText emotion"><span class="lessonHeading">How you did this day:</span> <span class="innerEmotion" :style="`color: ${color};`">{{emotion}}/10</span></p>
    <p class="lessonText reminder"><span class="lessonHeading">Topic: {{topicName ? topicName : 'Loading...'}}</span></p>
    <p class="lessonText description"><span class="lessonHeading">Description: <br/></span>{{description}}</p>
    <p class="lessonText reminder"><span class="lessonHeading">Reminder: <br/></span>{{reminder}}</p>
  </Box>
  <div class="favoriteWrap" @click="favoriteLesson">
    <span class="favText">Favorite</span>
    <img :src="checkFav(favorite) ? heart : heartLine" class="heart" alt="Toggle Favorite"/>
  </div>
</template>
<script setup>
import TopBar from "../components/TopBar.vue"
import Box from "../components/Box.vue"
import Mode from "../components/Mode.vue"
import api from "../../actors/api";
import { getColor, truncate, dateFormat } from "../../helpers/Functions";
import { useEmidyStore } from '../../store/emidystore';
import { useRoute } from 'vue-router';
import router from "@/router";
import { onBeforeMount, ref, computed } from "vue";
import heart from "@/assets/icons/heart.svg"
import heartLine from "@/assets/icons/heart-line.svg"
import { accent1, light, dark } from "../../helpers/Colors";
const route = useRoute();
const store = useEmidyStore();


let colorScheme = store.dark ? dark : light;

let editing = ref(false);
let deleteConfirm = ref(false);

let emotionRegex = new RegExp(/^[1-9]$|^10$/);

let title = ref(null);
let color = ref(null);
let lessonID = ref(null);
let description = ref(null);
let created = ref(null);
let emotion = ref(null);
let reminder = ref(null);
let favorite = ref(null);
let topicID = ref(null);
let topicName = ref(null);

let edittitle = ref(null);
let editdescription = ref(null);
let editemotion = ref(null);
let pendingeditemotion = ref(null);
let editreminder = ref(null);
let edittopicID = ref(null);


let userTopics = ref(null);



onBeforeMount(() => {
  title.value = route.query.title;
  color.value = route.query.color;
  lessonID.value = route.query.lessonID;
  description.value = route.query.description;
  created.value = route.query.created;
  emotion.value = route.query.emotion;
  reminder.value = route.query.reminder;
  favorite.value = route.query.favorite;
  topicID.value = route.query.topicID;

  edittitle.value = route.query.title;
  editdescription.value = route.query.description;
  editemotion.value = route.query.emotion;
  pendingeditemotion.value = route.query.emotion;
  editreminder.value = route.query.reminder;
  edittopicID.value = route.query.topicID;
  
  api.get("emidy/topics", store.token)
  .then(res => {
    if(res.status >= 400){
      console.log("Error getting topics")
    }else{
      res.json().then(data => {
        if(data.length == 0){
          userTopics.value = [{title: "No Topics Found", _id: ""}];
          alert("Please create a Topic first")
        }else{
          let tempTopics = data;
          
          // data.forEach((x) => {
          //   let topicData = {
          //     label: x.title,
          //     value: x._id
          //   }
            
          //   tempTopics.push(topicData);
          // })
          tempTopics.push({title: "Leave Current Topic", _id: route.query.topicID})

          userTopics.value = tempTopics;

          let top = data.find((t) => {return t._id == route.query.topicID});

          if(top){
            topicName.value = top.title;
          }
        }
      })
    }
  });
})

let checkFav = (f) => {
  if(f == true || f == "true"){
    return true;
  }else{
    return false;
  }
}

let beginDelete = () => {
  editing.value = false;
  deleteConfirm.value = true;
}

let cancelEdit = () => {
  edittitle.value = title.value;
  editdescription.value = description.value;
  editemotion.value = emotion.value;
  pendingeditemotion.value = emotion.value;
  editreminder.value = reminder.value;
  edittopicID.value = topicID.value;

  editing.value = false;
  deleteConfirm.value = false;
}



let emotionChange = () => {
  console.log(editemotion.value, pendingeditemotion.value);
  if(emotionRegex.test(pendingeditemotion.value) || pendingeditemotion.value == ""){
    editemotion.value = pendingeditemotion.value;
  }else if(!emotionRegex.test(pendingeditemotion.value)){
    pendingeditemotion.value = editemotion.value;
  }
}

const favoriteLesson = () => {
  let boolToUse = favorite.value == "true" || favorite.value == true ? false : true;

  favorite.value = boolToUse;

  api.get(`emidy/lesson/${lessonID.value}/favorite/${boolToUse}`, store.token)
  .then(res => {
    if(res.status >= 400){
      alert("Sorry, Try again later :(")
    }
  })
}


  const saveEditedLesson = () => {
    if(editemotion.value > 0 && editemotion.value < 11){
      let topicToUse = edittopicID.value || topicID.value;

      api.put("emidy/lesson", {
        _id: lessonID.value,
        title: edittitle.value,
        topicID: topicToUse,
        description: editdescription.value,
        emotion: editemotion.value,
        reminder: editreminder.value,
      }, store.token)
      .then(res => {
        if(res.status == 200){
          store.toggleForceUpdate();
          router.back();
        }else{
          console.log(res);
          alert("Sorry, Try again later :(");
        }
      })
    }else{
      alert("Input an Emotion from 1 - 10");
    }
  }

  const deleteLesson = () => {
    api.get(`emidy/delete_lesson/${lessonID.value}`, store.token)
    .then(res => {
      if(res.status >= 400){
        alert("Sorry, Try again later :(")
      }else{
        router.back();
      }
    })
  }

</script>
<style scoped>
.lessonSingleWrap{
  text-align: left;
}
.lessonHeading{
  font-weight: bold;
  font-size: 1.1rem;
}
.lessonText{
  margin-bottom: 30px;
}
.title{
  font-weight: bold;
  font-size: 1.3rem;
  margin: 0;
}
.created{
  font-size: 1rem;
  margin: 0;
}
.innerEmotion{
  font-weight: bold;
}

.favoriteWrap{
  width: 66%;
  border-radius: 5px;
  background-color: #ab93be;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 5px;
  margin-left: 16%;
}
.favText{
  font-weight: bold;
  font-size: 16px;
  color: #f2f2f2;
  margin-right: 10px;
}
.heart{
  filter: invert(13%) sepia(88%) saturate(7090%) hue-rotate(3deg) brightness(95%) contrast(116%);
  width: 28px;
}
.horLine{
  width: 80%;
  margin-left: 10%;
  border-bottom: 1px solid #777;
}
.wholeEdit{
  padding-bottom: 20px;
}
.textEditWrap{
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
  padding-left: 20px;
  padding-top: 20px;
}
.topicSelect{
  width: 80%;
  height: 33px;
  margin: 5px;
  color: black;
  background-color: white;
}
.topicOptions{
  color: black;
}
.editLabel{
  color: #f2f2f2;
  width: 100%;
  text-align: left;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
.editInput{
  width: 90%;
  padding: 10px;
}
.singleEditColor{
  margin: 7px;
  width: min-content;
  border-width: 2px;
  border-style: solid;
  border-radius: 40px;
}
.innerColorEdit{
  height: 35px;
  width: 35px;
  border-radius: 40px;
}
.editBtnWrap{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.subBtn{
  color: white;
  padding: 10px 20px;
  font-weight: bold;;
}
.greenBtn{
  color: green;
}
.ornBtn{
  color: orange;
}
.redBtn{
  color: red;
}
.reallyText{
  color: white;
}
</style>