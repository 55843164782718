<template>
<div>
  <TopBar></TopBar>

  <div :style="`color: ${colorScheme.text};`"> 
    <h3 class="feedbackHeader" >Hello {{store.name}}</h3>
    <p class="feedbackText" style="text-align: center; font-size: 17px; margin-bottom: 30px;">We appreciate you using Emidy!</p>
    <p class="feedbackText">Being a small team, any feedback really helps us understand what our users want.</p>
    <p class="feedbackText">Feel free to let us know any features or improvements you'd like to see.</p>
  </div>
  <br/>
  <textarea class="feedbackArea" placeholder="Enter Message" v-model="message" rows="7"></textarea>
  <br/>
  <br/>
  <br/>
  <button class="feedbackBtn" @click="send()">Submit</button>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
</div>
</template>
<script setup>
import TopBar from "../components/TopBar.vue"
import { useEmidyStore } from '../../store/emidystore';
import { onBeforeMount, ref, watch } from "vue";
import api from "../../actors/api";
import { accent1, light, dark } from "../../helpers/Colors";

const store = useEmidyStore();
let colorScheme = store.dark ? dark : light;


const message = ref(null);

watch(message, () => changeFeedback());

onBeforeMount(() => {
  if(store.feedback){
    message.value = store.feedback;
  }
})

let changeFeedback = () => {
  store.changeFeedback(message.value);
}

let send = () => {
  if(!message.value){
    alert("Please type a message")
  }else{ 
    api.post("feedback", {message: message.value}, store.token)
    .then((res) => {
      if(res.status == 200){
        store.changeFeedback("");
        message.value = "";
        alert("Message sent successfully");
      }else{
        alert("Sorry, Try again later :(");
      }
    })
  }
}


</script>
<style scoped>
.feedbackHeader{
  font-size: 1.4rem;
  margin-top: 20%;
}
.feedbackText{
  padding: 0 20px;
  font-weight: bold;
  line-height: 25px;
  text-align: left;
}
.feedbackArea{
  font-size: 16px;
  padding: 7px;
  width: 85%;
}
.feedbackBtn{
  font-size: 16px;
  width: 90%;
  background-color: #ab93be;
  border-radius: 20px;
  padding: 7px;
  color: white;
  font-weight: bold;
}
</style>