import { defineStore } from 'pinia';
import { useRoute } from 'vue-router'
import { ref, computed } from 'vue';
import router from '@/router';


// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useEmidyStore = defineStore('emidy', () => {
  const route = useRoute(); // Do not return things like this. Security hazard

  const count = ref(0);
  const name = ref(null);
  const email = ref(null);
  const verified = ref(null);
  const token = ref(null);
  const loggedIn = ref(false);
  const initialLaunch = ref(true); // Used in Dash to check things only once when app is opened.
  const pushNotificationsEnabled = ref(null);
  const dark = ref(false);
  const feedback = ref(null);
  const forceUpdate = ref(false);

  // const doubleCount = computed(() => count.value * 2);
  // function increment() {
  //   count.value++
  // }


  const login = (x) => {
    name.value = x.name;
    email.value = x.email;
    verified.value = x.verified;
    token.value = x.token;

    if(x.name && x.email && x.token){
      localStorage.setItem('emidy_token', x.token);
      router.replace('/dash');
    }
  }

  const logout = () => {
    name.value = null;
    email.value = null;
    verified.value = null;
    token.value = null;

    localStorage.removeItem('emidy_token');

    router.replace('/signin');
  }

  const changeMood = (c) => {
    if(c == 'light'){
      dark.value = false;
    }else if(c == 'dark'){
      dark.value = true;
    }
  }

  const changeFeedback = (f) => {
    feedback.value = f;
  }

  const toggleForceUpdate = () => {
    forceUpdate.value = !forceUpdate.value;
  }

  const changePushNotifications = (val) => {
    pushNotificationsEnabled.value = val;
  }
  const setupDone = () => {
    initialLaunch.value = false;
  }

  return { 
    count, 
    name, 
    email, 
    verified, 
    token, 
    dark, 
    loggedIn, 
    feedback, 
    forceUpdate, 
    pushNotificationsEnabled, 
    initialLaunch, 
    login, 
    logout, 
    changeMood, 
    changeFeedback, 
    toggleForceUpdate, 
    changePushNotifications, 
    setupDone 
  }
})