// import { dark, light, accent1, accent2, badColor, poorColor, goodColor, greatColor } from "./Colors";
let { dark, light, accent1, accent2, badColor, poorColor, goodColor, greatColor } = require("./Colors");

let truncate = (string, limit) => {
  if(!string){
    return "";
  }
  if(string.length <= limit){
    return string;
  }else{
    return string.slice(0, limit) + "...";
  }
}

let dateFormat = (date) => {
  if(date){
    // console.log(date);
    let s;
    if(typeof date == "string" && date.includes("-")){
      s = date;
    }else{
      s = new Date(parseInt(date)).toISOString();
    }
    // Used before changed date in db to timestamp
    let splitD = s.split("T")[0];
    let pD = splitD.split("-");
    return `${pD[1]}/${pD[2]}/${pD[0]}`
  }
}


let getColor = (emotion) => {
  if(emotion < 4){
    return badColor;
  }else if(emotion == 4 || emotion == 5){
    return poorColor;
  }else if(emotion == 6 || emotion == 7){
    return goodColor;
  }else if(emotion >= 8 && emotion <= 10){
    return greatColor;
  }else{
    return "#000"
  }
}

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

module.exports = {
  truncate,
  dateFormat,
  getColor,
  urlBase64ToUint8Array
}