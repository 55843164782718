<template>
<div>
  <TopBar></TopBar>
  <div :style="`color: ${colorScheme.text};`">

    <p class="topicText">Topics are a way to keep your lessons organized.</p>
    <p class="topicText">These can be different emotions, goals, or anything you want to track!</p>
    <p class="topicText">(The best Topics are 1 - 2 words)</p>
  </div>
  <div class="horLine"></div>

  <div class="textInputWrap">
    <label class="inputLabel">Title</label>
  </div>
  <input v-model="title" class="topicInput" placeholder="Topic Title"/>
  <br/>
  <div class="textInputWrap">
    <label class="inputLabel" >Description/Goal</label>
  </div>
  <textarea v-model="description" class="topicInput" rows="5" placeholder="Describe a clear goal for this Topic"></textarea>
  <br/>

  <p class="createBtn" @click="createTopic" :style="`background-color: ${accent1};`">Create Topic</p>

  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
</div>
</template>
<script setup>
import TopBar from "../components/TopBar.vue"
import Box from "../components/Box.vue"
import api from "../../actors/api";
import { ref } from "vue";
import { useEmidyStore } from '../../store/emidystore';
import { truncate } from "../../helpers/Functions";
import { accent1, light, dark } from "../../helpers/Colors";
import router from "@/router";


const store = useEmidyStore();


let colorScheme = store.dark ? dark : light;

let title = ref(null);
let description = ref(null);


let createTopic = () => {
  api.post("emidy/topic", {title: title.value, description: description.value}, store.token)
  .then((res) => {
    if(res.status == 200){
      router.back();
    }else{
      alert("Sorry, Try again later :(");
    }
  })
}

</script>
<style scoped>
.topicText{
text-align: left;
font-weight: bold;
font-size: 1.1rem;
padding: 2px 15px;
}
.textInputWrap{
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
  padding-left: 20px;
  padding-top: 20px;
}
.inputLabel{
  color: black;
  width: 100%;
  text-align: left;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
.topicInput{
  width: 90%;
  padding: 10px;
}

.horLine{
  width: 80%;
  margin-left: 10%;
  border-bottom: 1px solid #777;
}

.createBtn{
  width: 80%;
  margin-left: 10%;
  padding: 7px;
  color: #f2f2f2;
  font-weight: bold;
  border-radius: 7px;
  margin-top: 20px;
  border: 1px solid grey;
}
</style>