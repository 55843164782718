<template>
  <div class="fullLessonsPage">
    <TopBar></TopBar>
    <CreateNewBtn text="Create New Lesson" :color="accent2" @create="createNew"></CreateNewBtn>
    <div class="horLine"></div>

    <div v-if="weekLessons && !loading" class="allLessons">
      <p class="timeSection">This Week</p>
      <div v-for="l in weekLessons" :key="l.id" class="lessonWrapper">
        <LessonBox :l="l"></LessonBox>
      </div>
      <br/>
    </div>
    <div v-if="monthLessons && !loading" class="allLessons">
      <p class="timeSection">This Month</p>
      <div v-for="l in monthLessons" :key="l.id" class="lessonWrapper">
        <LessonBox :l="l"></LessonBox>
      </div>
       <br/>
    </div>
    <div v-if="oldLessons && !loading" class="allLessons">
      <p class="timeSection">Older Lessons</p>
      <div v-for="l in oldLessons" :key="l.id" class="lessonWrapper">
        <LessonBox :l="l"></LessonBox>
        <!-- {{l}} -->
      </div>
       <br/>
    </div>



    <div v-if="!weekLessons && !monthLessons && !oldLessons && !loading">
      <p class="info" :style="`color: ${colorScheme.text};`">After You've Made A Topic, <br/>Create A Lesson</p>
    </div>

    <div v-if="loading">
      <p class="info" :style="`color: ${colorScheme.text};`">Loading...</p>
    </div>

  </div>
</template>
<script setup>
import TopBar from "../components/TopBar.vue"
import CreateNewBtn from "../components/CreateNewBtn.vue"
import LessonBox from "../components/LessonBox.vue"
import { accent2, light, dark } from "../../helpers/Colors";
import { computed, onBeforeMount, onMounted, ref } from "vue";
import api from "../../actors/api";
import { useEmidyStore } from '../../store/emidystore';
import { truncate } from "../../helpers/Functions";
import router from "@/router";

const store = useEmidyStore();

let colorScheme = store.dark ? dark : light;

const weekLessons = ref(null);
const monthLessons = ref(null);
const oldLessons = ref(null);
const loading = ref(true);


let createNew = () => {
  router.push("/createlesson");
}

onBeforeMount(() => {
  fetchData();
})

let fetchData = () => {
  if(store.token){
    api.get("emidy/lessons", store.token)
      .then(res => {
        if(res.status >= 400){
          alert("Sorry, please come back later :(")
        }else{
          res.json().then(data => {
            let tempWeek = [];
            let tempMonth = [];
            let tempOld = [];

            data.forEach((l) => {
              // Today's date in Milliseconds
              const start = Date.now();

              // Lessons date in Milliseconds
              let myDate = new Date(l.created);
              let result = myDate.getTime();
              if(start - result < 604800000){
                tempWeek.push(l);
              }else if(start - result < 2629743833.3){
                tempMonth.push(l);
              }else{
                tempOld.push(l);
              }
            })

            if(tempWeek.length > 0){
              weekLessons.value = tempWeek;
            }
            if(tempMonth.length > 0){
              monthLessons.value = tempMonth;
            }
            if(tempOld.length > 0){
              oldLessons.value = tempOld;
            }
            loading.value = false;
          })
        }
      })
  }
}





</script>
<style scoped>
.fullLessonsPage{
  padding-bottom: 80px;
}
.horLine{
  background-color: rgba(0,0,0,.1);
  height: 1px;
  width: 80%;
  margin-left: 10%;
  margin-bottom: 7%;
  margin-top: 5%;
}
.info{
  font-size: 18px;
  font-weight: bold;
}
.timeSection{
  font-size: 18px;
  font-family: "OpenSansBold";
  color: #888;
  font-weight: bold;
}
</style>