const backUri = process.env.NODE_ENV == "production" ? 'https://api.emidy.app/' : process.env.VUE_APP_DEV_SERVER;

module.exports = {
  get: (url, token) => (
    fetch(`${backUri}${url}`, {
      method: "GET",
      headers: {
        "x_emidy_key": 'uh2u98HOohR99r9RHWe32OHG442IHOFEFW',
        "authorization": `Bearer ${token}`,
      },
    }).then(res => {
      return res
    })
  ),
  post: (url, payload, token) => (
    fetch(backUri + url, {
      method: "POST",
      headers: {
        "x_emidy_key": 'uh2u98HOohR99r9RHWe32OHG442IHOFEFW',
        "authorization": `Bearer ${token}`,
        "content-type": "application/json"
      },
      body: JSON.stringify(payload)
    }).then(res => {
      return res;
    })
  ),
  put: (url, payload, token) => (
    fetch(backUri + url, {
      method: "PUT",
      headers: {
        "x_emidy_key": 'uh2u98HOohR99r9RHWe32OHG442IHOFEFW',
        "authorization": `Bearer ${token}`,
        "content-type": "application/json"
      },
      body: JSON.stringify(payload)
    }).then(res => {
      return res;
    })
  ),
  login: (payload) => (
    fetch(backUri + 'users/login', {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify(payload)
    }).then(res => {return res})
  ),
  signup: (payload) => (
    fetch(backUri + 'users/add_user', {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify(payload)
    }).then(res => {return res})
  ),
  checkout: (token) => (
    fetch(backUri + 'stripe/create-checkout-session', {
      method: 'POST',
      headers: {
      "x_emidy_key": 'uh2u98HOohR99r9RHWe32OHG442IHOFEFW',
      "authorization": `Bearer ${token}`,
      "Content-Type": 'application/json'
      } 
    }).then(res => {return res})
  ),
  update: (token) => (
    fetch(backUri + 'stripe/create-portal-session', {
      method: 'POST',
      headers: {
      "x_emidy_key": 'uh2u98HOohR99r9RHWe32OHG442IHOFEFW',
      "authorization": `Bearer ${token}`,
      "Content-Type": 'application/json'
      } 
    }).then(res => {return res})
  )
}