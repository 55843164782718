<template>
<div>
  <Box>
    <p class="weekText" :style="`color: ${colorScheme.text};`">-This week in review-</p>

    <div class="weekBtnWrap">
      <div class="weekDayWrap" :style="`background-color: ${color0 ? color0 : colorScheme.background};`" @click="dayClick(fullWeek[0])"><p :style="`color: ${color0 ? '#fff' : colorScheme.text};`" v-text="weekDay0"></p></div>
      <div class="weekDayWrap" :style="`background-color: ${color1 ? color1 : colorScheme.background};`" @click="dayClick(fullWeek[1])"><p :style="`color: ${color1 ? '#fff' : colorScheme.text};`" v-text="weekDay1"></p></div>
      <div class="weekDayWrap" :style="`background-color: ${color2 ? color2 : colorScheme.background};`" @click="dayClick(fullWeek[2])"><p :style="`color: ${color2 ? '#fff' : colorScheme.text};`" v-text="weekDay2"></p></div>
      <div class="weekDayWrap" :style="`background-color: ${color3 ? color3 : colorScheme.background};`" @click="dayClick(fullWeek[3])"><p :style="`color: ${color3 ? '#fff' : colorScheme.text};`" v-text="weekDay3"></p></div>
      <div class="weekDayWrap" :style="`background-color: ${color4 ? color4 : colorScheme.background};`" @click="dayClick(fullWeek[4])"><p :style="`color: ${color4 ? '#fff' : colorScheme.text};`" v-text="weekDay4"></p></div>
      <div class="weekDayWrap" :style="`background-color: ${color5 ? color5 : colorScheme.background};`" @click="dayClick(fullWeek[5])"><p :style="`color: ${color5 ? '#fff' : colorScheme.text};`" v-text="weekDay5"></p></div>
      <div class="weekDayWrap" :style="`background-color: ${color6 ? color6 : colorScheme.background};`" @click="dayClick(fullWeek[6])"><p :style="`color: ${color6 ? '#fff' : colorScheme.text};`" v-text="weekDay6"></p></div>
    </div>
    <!-- <div class="weekDayWrap" :style="`background-color: ${color6 ? color6 : colorScheme.background};`" @click="dayClick(fullWeek[6])"><p :style="`color: ${color6 ? '#111' : colorScheme.text};`" v-text="weekDay6"></p></div> -->
    <!-- <div v-if="loading" class="loading">Loading...</div> -->
  </Box>
</div>
</template>
<script setup>
import Box from "./Box.vue";
import { accent2, light, dark } from "../../helpers/Colors";
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";
import api from "../../actors/api";
import { useEmidyStore } from '../../store/emidystore';
import { truncate, getColor } from "../../helpers/Functions";
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import router from "@/router";


const store = useEmidyStore();

let weekDay0 = ref(null);
let weekDay1 = ref(null);
let weekDay2 = ref(null);
let weekDay3 = ref(null);
let weekDay4 = ref(null);
let weekDay5 = ref(null);
let weekDay6 = ref(null);
let fullWeek = ref(null);
let color0 = ref(null);
let color1 = ref(null);
let color2 = ref(null);
let color3 = ref(null);
let color4 = ref(null);
let color5 = ref(null);
let color6 = ref(null);

let loading = ref(true)

let colorScheme = store.dark ? dark : light;

const makeDays = () => {
  const d = new Date();

    weekDay0.value = subDays(d, 6).getDate();
    weekDay1.value = subDays(d, 5).getDate();
    weekDay2.value = subDays(d, 4).getDate();
    weekDay3.value = subDays(d, 3).getDate();
    weekDay4.value = subDays(d, 2).getDate();
    weekDay5.value = subDays(d, 1).getDate();
    weekDay6.value = d.getDate();
}

const makeWeek = () => {
  let FullWeekDate = new Date();
  const offset = FullWeekDate.getTimezoneOffset();
  FullWeekDate = new Date(FullWeekDate.getTime() - (offset*60*1000));
  
  let tempFullWeek = [
    subDays(FullWeekDate, 6).toISOString().split("T")[0],
    subDays(FullWeekDate, 5).toISOString().split("T")[0],
    subDays(FullWeekDate, 4).toISOString().split("T")[0],
    subDays(FullWeekDate, 3).toISOString().split("T")[0],
    subDays(FullWeekDate, 2).toISOString().split("T")[0],
    subDays(FullWeekDate, 1).toISOString().split("T")[0],
    FullWeekDate.toISOString().split("T")[0]
  ]
  return tempFullWeek
}


  const getAvg = () => {
    for(let i= 0; i < fullWeek.value.length; i++){
      api.get(`emidy/lessons_avg/${fullWeek.value[i]}`, store.token)
      .then(res => {
        if(res.status >= 400){
        }else if(res.status == 202){
          if(i == 0){
            color0.value = "";
          } else if (i == 1){
            color1.value = "";
          }else if (i == 2){
            color2.value = "";
          }else if (i == 3){
            color3.value = "";
          }else if (i == 4){
            color4.value = "";
          }else if (i == 5){
            color5.value = "";
          }else if (i == 6){
            color6.value = "";
          }
        }else{
          res.json().then((data) => {
            if(i == 0){
              color0.value = getColor(data);
            } else if (i == 1){
              color1.value = getColor(data);
            }else if (i == 2){
              color2.value = getColor(data);
            }else if (i == 3){
              color3.value = getColor(data);
            }else if (i == 4){
              color4.value = getColor(data);
            }else if (i == 5){
              color5.value = getColor(data);
            }else if (i == 6){
              color6.value = getColor(data);
            }
          })
        }

        loading.value = false;
      })
    }
  }

  watch(store, () => fetchData());

  onMounted(() => {
    if(store.token){
      fetchData();
    }
  })

  let fetchData = () => {
    makeDays();
    fullWeek.value = makeWeek();
    getAvg();
  }

let dayClick = (day) => {
  router.push({path: "/lessons/list", query: {date: day}});
}


</script>
<style scoped>
.weekText{
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  font-weight: bold;
}
.weekBtnWrap{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0;
}
.weekDayWrap{
  border-radius: 5px;
}
.weekDayWrap p{
  padding: 5px;
  margin: 0;
  font-weight: bold;
  min-width: 30px;
}
</style>