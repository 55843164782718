<template>
<div class="dashPage">
  <TopBar></TopBar>
  <p class="reminder" :style="`color: ${colorScheme.text};`">"The first step is always the hardest."</p>
  
  <WeeklySummary></WeeklySummary>
  <br/>
  <MainChart></MainChart>
  
  <FeaturedArticles></FeaturedArticles>

</div>
</template>
<script setup>
import TopBar from "../components/TopBar.vue"
import WeeklySummary from "@/components/WeeklySummary.vue";
import FeaturedArticles from "@/components/FeaturedArticles.vue";
import MainChart from "@/components/MainChart.vue";
import { onMounted } from "vue";
import { light, dark } from "../../helpers/Colors";
import { useEmidyStore } from '../../store/emidystore';
import api from "../../actors/api";

const store = useEmidyStore();

let colorScheme = store.dark ? dark : light;

onMounted(() => {
  window.scrollTo(0, 0);

  // Checks if user has push notifications enabled & updates endpoint
  try{
    if(navigator.serviceWorker && store.initialLaunch){
      navigator.serviceWorker.ready.then(reg => {
        reg.pushManager.getSubscription().then(sub => {
          if(sub == undefined){
            // ask user to register for push
            // Currently doing nothing
          }else{
            // We have subscription, update db
            // console.log(sub);
            if(store.token){
              api.post("notifications/user_push_credentials", {
                web_push_credentials: JSON.stringify(sub)
              }, store.token)
              .then(res => {
                if(res.status >= 400){
                  alert("Sorry, Try again later :(")
                }else{
                  store.changePushNotifications(true);
                  store.setupDone(); // Turns off initialLaunch
                }
              })
            }
          }
        })
      })
    }
  }
  catch(error){
    console.log(error)
  }
})

</script>
<style scoped>
.dashPage{
  padding-bottom: 50px;
}
.reminder{
  padding: 10px;
  font-weight: bold;
  font-style: italic;
}
  
</style>