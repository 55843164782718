<template>
<div style="height: 100vh;">
<div class="container">
  
  <Box class="box" style="background-color: '#f9f9f9'">
    <div style="styles.imgWrapper">
      <img alt="Vue logo" src="../assets/logo.png" class="signuplogo">
      <div class="betaWrapper">
        <p class="beta">Beta: V{{version}}</p>
      </div>
    </div>
    <h1 class="welcome">Sign Up <span class="flavor">Join The Family :)</span></h1>

    <p style="color: red; textAlign: 'center'; font-size: 12px;" @click="removeError()">{{errorText ? `${errorText} (x)` : '&nbsp;'}}</p>
    <div v-if="loading" style="color: 'grey'; textAlign: 'center'">Loading...</div>
    <div class="horLine"></div>

    <input class="inputStyles" placeholder='Name' placeholderTextColor="#888" v-model="name" />
    <br/>
    <input class="inputStyles" placeholder='Email' placeholderTextColor="#888" v-model="email" />
    <br/>
    <input type="password" class="inputStyles" placeholder='Password' placeholderTextColor="#888" v-model="password" />
    <br/>
    <input type="password" class="inputStyles" placeholder='Confirm Password' placeholderTextColor="#888" v-model="confirm" />
    <br/>
    <input class="inputStyles" placeholder='Date of Birth: mm/dd/yyyy' placeholderTextColor="#888" v-model="webDob" />
    <br/>
    <br/>
    <button class="submitBtn" @click="loginSubmit()">Sign Up</button>

    <br/>
    <br/>
    <div class="horLine"></div>
    <p @click="goToSignIn()" class="switchBtn" >Need an account? Sign up now!</p>
  </Box>
</div>
</div>
</template>
<script setup>
import Box from "@/components/Box.vue";
import { onMounted, ref } from "vue";
import { version } from "../../package.json"
import api from "../../actors/api";
import router from "@/router";
import { useEmidyStore } from '../../store/emidystore';

const store = useEmidyStore();

const errorText = ref(null);
const loading = ref(false);

const name = ref(null);
const email = ref(null);
const password = ref(null);
const confirm = ref(null);
const webDob = ref(null);

onMounted(() => {
})


let removeError = () => {
  errorText.value = null;
}

let loginSubmit = () => {
  signup();
}

let goToSignIn = () => {
  router.push("/signin");
}


let checkDOB = (x) => {
  let thisYear = new Date().getFullYear();
  let splitDate = x.split("/");
  const numRegex = new RegExp(/^[0-9]*$/);


  if(splitDate.length != 3){
    return false;
  }

  if(
    (!numRegex.test(splitDate[0])) ||
    (!numRegex.test(splitDate[1])) ||
    (!numRegex.test(splitDate[2]))
  ){
    return false;
  }

  if(
    (parseInt(splitDate[0]) <= 0 || parseInt(splitDate[0]) >= 13) &&
    (parseInt(splitDate[1]) <= 0 && parseInt(splitDate[1]) >= 32) &&
    (parseInt(splitDate[2]) <= 1900 && parseInt(splitDate[2]) >= thisYear)
  ){
    return false;
  }

  if(
    (parseInt(splitDate[0]) > 0 && parseInt(splitDate[0]) < 13) &&
    (parseInt(splitDate[1]) > 0 && parseInt(splitDate[1]) < 32) &&
    (parseInt(splitDate[2]) > 1900 && parseInt(splitDate[2]) < thisYear)
  ){
    return true;
  }
}

const signup = () => {
  errorText.value = null;
  const webRegex = new RegExp(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/);
  const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);    
  // ISO String REGEX For Server : let regex = new RegExp(/(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/)
  if(
    !name.value ||
    !email.value ||
    !password.value ||
    !confirm.value ||
    !webDob.value
  ){
    errorText.value = "All Fields Are Required";
  }
  else if(!emailRegex.test(email.value)){
    errorText.value = "Enter A Valid Email";
  }
  else if(password.value.length < 6 || password.value.length > 16){
    errorText.value = "Password 6 - 16 Characters";
  }
  else if(password.value !== confirm.value){
    errorText.value = "Passwords Do Not Match";
  }
  else if(!checkDOB(webDob.value)){
    errorText.value = "Date Format Incorrect";
  }
  else{
    loading.value = true;
    let tempObj = {
      name: name.value,
      email: email.value,
      password: password.value,
      dobToUse: ""
    };


    let sD = webDob.value.split("/");
    tempObj.dobToUse = `${sD[2]}-${sD[0]}-${sD[1]}`
    


    api.signup(tempObj)
    .then(res => {
      loading.value = false;
      if(res.status == 420){
        errorText.value = "Please Try Again Later";
      }
      else if(res.status == 409){
        errorText.value = "Email Already Taken";
      }
      else if(res.status == 404){
        errorText.value = "All Fields Required";
      }
      else if(res.status == 500){
        errorText.value = "Please Try Again Later";
      }
      else{
        res.json().then(data => {
          let tempUsr = {
            name: data.userInfo.name,
            email: data.userInfo.email,
            verified: data.userInfo.verified,
            token: data.token
          }
          store.login(tempUsr);
        })
      }
    })
  }
}



</script>
<style scoped>
  .container{
    margin: 0;
    background-color: #e8eafc; 
    height: 100vh;
    padding: 10px;
  }
  .imgWrapper{
    background-color: 'rgba(0,0,0,0)';
    margin: 20px;
    width: 50px;
  }
  .box{
    background-color: #f9f9f9;
  }
  .signuplogo{
    width: 70px;
  }
  .betaWrapper{
    background-color: #e8eafc;
    border-radius: 20px;
    padding: 0 1px;
    margin-top: 10px;
    width: 20%;
    margin-left: 40%;
    margin-bottom: 10px;
  }
  .beta{
    font-size: 9px;
    font-weight: bold;
    color: #666;
  }
  .welcome{
    font-family: "OpenSansEB";
    font-size: 1.7rem;
    font-weight: bold;
    text-align: left;
  }
  .flavor{
    font-size: 12px;
    color: rgba(0,0,0,.5);
    font-weight: 100;
    font-family: "OpenSans";
  }
  .horLine{
    background-color: rgba(0,0,0,.1);
    height: 1px;
    width: 94%;
    margin-left: 3%;
    margin-bottom: 3%;
  }
  .inputStyles{
    width: 90%;
    padding: 7px;
    margin: 5px;
    border-radius: 5px;
    border-width: 1px;
    border-width: rgbe(0,0,0,.5);
  }
  .submitBtn{
    width: 100%;
    border: none;
    background-color: #ab93be;
    border-radius: 3px;
    font-size: 15px;
    padding: 10px;
    color: white;
    font-weight: bold;
  }
  .switchBtn{
    /* padding-top: 10px; 
    padding-bottom: 10px; */
    font-size: 13px;
    text-align: left;
    color: #2f95dc;
  }
</style>