<template>
<div>
  
</div>
</template>
<script setup>
import router from "@/router";
import { onBeforeMount } from "vue";

onBeforeMount(() => {
    router.replace("/signin");
})

</script>
<style scoped>

</style>