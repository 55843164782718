<template>
<div>
  <div class="allLesson" @click="router.push({path: '/lessons/lesson', query: {title: l.title, color: getColor(l.emotion), lessonID: l._id, description: l.description, created: l.created, emotion: l.emotion, reminder: l.reminder, favorite: l.favorite, topicID: l.topicID}})">
    <div class="countWrap">
      <p :style="`background-color: ${getColor(props.l.emotion)}`" class="count">{{props.l.emotion}}</p>
    </div>
    <div class="lessonWrap" :style="`color: ${colorScheme.text};`">
      <p class="date">{{dateFormat(props.l.created)}}</p>
      <p class="title">{{props.l.title}}</p>
      <p class="reminder">{{truncate(props.l.reminder, 35)}}</p>
      <img v-if="l.favorite" src="../assets/icons/heart.svg" alt="favorite" class="heart"/>
      <!-- <img v-if="!l.favorite" src="../assets/icons/heart-line.svg" alt="favorite" class="heart"/> -->
    </div>
  </div>
  <div class="horLine"></div>
</div>
</template>
<script setup>
import { ref , onMounted, defineProps } from "vue";
import { accent2, light, dark } from "../../helpers/Colors";
import { truncate, dateFormat, getColor } from "../../helpers/Functions";
import { useRoute } from 'vue-router';
import router from "@/router";
import { useEmidyStore } from '../../store/emidystore';
const route = useRoute();

const store = useEmidyStore();

let colorScheme = store.dark ? dark : light;

const props = defineProps(["l"]);





</script>
<style scoped>
.allLesson{
  width: 100%;
  min-height: 10px;
  display: flex;
  flex-direction: row;
  padding: 3px 13px; 
  margin-bottom: 5px;
}
.countWrap{
  width: 7%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.count{
  color: #222;
  font-weight: 500;
  padding: 35px 0;
  width: 70%;
  border-radius: 8px;
  font-size: 12px;
  margin: 0;
}


.lessonWrap{
  width: 93%;
  text-align: left;
  padding: 10px;
  position: relative;
}
.lessonWrap p{
  margin: 0;
  padding: 0;
}
.date{
  font-size: 14px;
  font-weight: 500;
}
.title{
  font-weight: bold;
}
.reminder{
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px !important;
}

.horLine{
  width: 90%;
  margin-left: 5%;
  margin-bottom: 10px;
  border-bottom: 1px solid #d2d2d2;
}

.heart{
  position: absolute;
  filter: invert(13%) sepia(88%) saturate(7090%) hue-rotate(3deg) brightness(95%) contrast(116%);
  width: 25px;
  top: 10%;
  right: 10%;
}
</style>