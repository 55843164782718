<template>
<div>
  <Box v-if="lessonsNum && !loading">
    <p class="totalLessonsWrap" :style="`color: ${subText};`">Total Lessons: <span class="totalLessonsInner" :style="`color: ${colorScheme.text};`">{{lessonsNum}}</span></p>

    <div class="barGraph" :style="`border-left: 1px solid ${colorScheme.backgroundSecondary}; border-bottom: 1px solid ${colorScheme.backgroundSecondary};`">
      <div class="innerBar" :style="`height: ${greatHeight}%; background-color: ${greatColor};`"></div>
      <div class="innerBar" :style="`height: ${goodHeight}%; background-color: ${goodColor};`"></div>
      <div class="innerBar" :style="`height: ${poorHeight}%; background-color: ${poorColor};`"></div>
      <div class="innerBar" :style="`height: ${badHeight}%; background-color: ${badColor};`"></div>
    </div>
    <div class="underBarText">
      <div class="underBarTextColumn">
        <p class="underBar1" :style="`color: ${subText};`">8-10</p>
        <p class="underBar2" :style="`color: ${colorScheme.text};`">{{greatNum}}</p>
      </div>
      <div class="underBarTextColumn">
        <p class="underBar1" :style="`color: ${subText};`">6-7</p>
        <p class="underBar2" :style="`color: ${colorScheme.text};`">{{goodNum}}</p>
      </div>    
      <div class="underBarTextColumn">
        <p class="underBar1" :style="`color: ${subText};`">4-5</p>
        <p class="underBar2" :style="`color: ${colorScheme.text};`">{{poorNum}}</p>
      </div>    
      <div class="underBarTextColumn">
        <p class="underBar1" :style="`color: ${subText};`">0-3</p>
        <p class="underBar2" :style="`color: ${colorScheme.text};`">{{badNum}}</p>
      </div>
    </div>
    <p class="xLabel" :style="`color: ${subText};`">% of Lessons</p>
  </Box>

  <Box v-if="!lessonsNum && !loading"><p :style="`color: ${colorScheme.text}; font-weight: bold;`">Create Topics & Lessons To See Analytics</p></Box>
  <Box v-if="loading"><p :style="`color: ${colorScheme.text}; font-weight: bold;`">Loading...</p></Box>
</div>
</template>
<script setup>
import Box from "./Box.vue";
import { accent2, light, dark, badColor, poorColor, goodColor, greatColor } from "../../helpers/Colors";
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";
import api from "../../actors/api";
import { useEmidyStore } from '../../store/emidystore';
import { truncate, getColor } from "../../helpers/Functions";
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import router from "@/router";

const store = useEmidyStore();

let colorScheme = store.dark ? dark : light;
let subText = store.dark ? 'lightgrey' : '#777';

let loading = ref(true);

let badHeight = ref(null);
let poorHeight = ref(null);
let goodHeight = ref(null);
let greatHeight = ref(null);
let lessonsNum = ref(null)
let badNum = ref(null);
let poorNum = ref(null);
let goodNum = ref(null);
let greatNum = ref(null);



watch(store, () => fetchData());

onMounted(() => {
 fetchData();
})

let fetchData = () => {
  if(store.token){
    api.get("emidy/dash_lessons_info", store.token)
    .then((res) => {
      if(res.status == 200){
        res.json().then((data) => {
          // console.log(data);
          let tempBad = data.badNum;
          let tempPoor = data.poorNum;
          let tempGood = data.goodNum;
          let tempGreat = data.greatNum;
          
          if(tempBad == 0){
            tempBad = .01;
          }
          if(tempPoor == 0){
            tempPoor = .01;
          }
          if(tempGood == 0){
            tempGood = .01;
          }
          if(tempGreat == 0){
            tempGreat = .01;
          }

          badHeight.value = ((tempBad / data.lessonsNum) * 100)
          poorHeight.value = ((tempPoor / data.lessonsNum) * 100)
          goodHeight.value = ((tempGood / data.lessonsNum) * 100)
          greatHeight.value = ((tempGreat / data.lessonsNum) * 100)
          lessonsNum.value = data.lessonsNum;
          badNum.value = data.badNum;
          poorNum.value = data.poorNum;
          goodNum.value = data.goodNum;
          greatNum.value = data.greatNum;
        })
      }

      loading.value = false;
    })
  }
}




</script>
<style scoped>
.totalLessonsWrap{
  font-size: 17px;
  font-weight: bold;
  text-align: left;
  margin: 0;
  padding: 0;
}
.totalLessonsInner{
  font-size: 20px;
}
.xLabel{
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
.barGraph{
  margin-top: 10px;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
}
.innerBar{
  width: 20%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.underBarText{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.underBar1{
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: .8rem;
}
.underBar2{
  padding: 0;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.2rem;
}
</style>