<template>
<div>
  <div v-if="isMobile"> 
    <router-view/>
  </div>
  <div v-else>
    <!-- Not Mobile -->
    <img alt="Vue logo" src="./assets/logo.png" class="logoOnDesktop">
    <p class="desktopText" :style="`color: ${colorScheme.text};`">Thank you for visiting Emidy!</p>
    <p class="desktopText" :style="`color: ${colorScheme.text};`">Please view on mobile device to continue.</p>
    <p class="desktopText" :style="`color: ${colorScheme.text};`"><span style="text-decoration: underline;">To download Emidy on a mobile device:</span></p>
    <p class="desktopText" :style="`color: ${colorScheme.text};`">1. Scan the QR code below or visit <span :style="`color: ${accent2};`">https://dash.emidy.app</span><br/> </p>
    <p class="desktopText" :style="`color: ${colorScheme.text};`">2. Click the Share or Options icon (<img alt="QR code for Emidy" src="./assets/icons/share.png" style="width: 20px;"> or <img alt="QR code for Emidy" src="./assets/icons/dots.svg" style="width: 4px;"> ) within your browser and Add To Homescreen.</p>
    <img alt="QR code for Emidy" src="./assets/images/QR.svg" style="width: 150px;">
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
  </div>
</div>
</template>
<script setup>
import { useEmidyStore } from '../store/emidystore';
import { onBeforeMount, onMounted, onUpdated, ref, onBeforeUnmount } from 'vue'
import router from './router';
import { useRoute } from 'vue-router';
import { accent2, dark, light} from "../helpers/Colors";
import api from "../actors/api";

// access the `store` variable anywhere in the component ✨
const store = useEmidyStore();
const route = useRoute();


let colorScheme = store.dark ? dark : light;

const isMobile = ref(true);


onBeforeMount(() => {
  
})

onMounted(() => {
  // Check if mobile view
  if((window.screen.width > window.screen.height) && window.screen.width > 950){
    isMobile.value = false;
  }else{
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // dark mode
      store.changeMood('dark');
      document.querySelector("html").style.background = dark.background;
    }else{
      store.changeMood('light');
      document.querySelector("html").style.background = light.background;
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      const newColorScheme = event.matches ? "dark" : "light";
      store.changeMood(newColorScheme);
      document.querySelector("html").style.background = newColorScheme == 'dark' ? dark.background : light.background;
    });

    AutoLogin();
  }
})

onUpdated(() => {
  AutoLogin();
})

let AutoLogin = () => {
  const emidyToken = localStorage.getItem('emidy_token');

  if((!store.loggedIn || !store.email || !store.name) && emidyToken){
    api.get("users/auto_login", emidyToken)
    .then(res => res.json())
    .then(res => {
      if(typeof res == "object"){
        let data = JSON.parse(JSON.stringify(res));
        store.login({...data, token: emidyToken})
      }else{
        store.logout();
      }
    });
  }else if((store.loggedIn || store.email || store.name) && !emidyToken){
    store.logout();
  }
}



</script>
<style>
@font-face {
    font-family: "SpaceMono";
    src: url('./assets/fonts/SpaceMono-Regular.ttf');
}
@font-face {
    font-family: "OpenSansEB";
    src: url('./assets/fonts/OpenSans-ExtraBold.ttf');
}
@font-face {
    font-family: "OpenSansBold";
    src: url('./assets/fonts/OpenSans-Bold.ttf');
}
@font-face {
    font-family: "OpenSansSB";
    src: url('./assets/fonts/OpenSans-SemiBold.ttf');
}
@font-face {
    font-family: "OpenSans";
    src: url('./assets/fonts/OpenSans-Regular.ttf');
}
@font-face {
    font-family: "OpenSansLight";
    src: url('./assets/fonts/OpenSans-Light.ttf');
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select,
  textarea,
  input {
    font-size: 16px;
  }
}


body, html{
  padding: 0;
  margin: 0;
}
* {
    box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.logoOnDesktop{
  width: 100px;
  margin-top: 50px;
  margin-bottom: 10px;
}
.desktopText{
  font-size: 1.5rem;
  font-weight: bold;
}

</style>
