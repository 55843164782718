<template>
<div>
  <TopBar></TopBar>

    <p class="profileName" :style="`color: ${colorScheme.text};`">{{store.name}}</p>
  
    <div class="horLine" :style="`border-bottom: 1px solid ${colorScheme.backgroundSecondary};`"></div>

    <Box class="profileBox box1" :style="`color: ${colorScheme.text};`">
      <p class="boxHeader">User Info</p>
      <p class="box1Text">Email: {{store.email}}</p>
      <p class="box1Text">Email Verified: <span :style="`color: ${verified == 'Yes' ? 'green' : 'red'}`">{{verified}}</span></p>
    </Box>

    <Box class="profileBox" :style="`color: ${colorScheme.text};`">
      <p class="boxHeader">Plan & Payment Info</p>
      <p class="trialText1" :style="`color: ${accent1};`">Enjoy Free Beta!</p>
      <p class="trialText2" >60 Days Left Of Trial</p>
      <button @click="showCheckout()" type="submit" class="subBtn" >Update Plan</button>
      <div class="horLine" :style="`border-bottom: 1px solid ${colorScheme.backgroundSecondary};`"></div>
      <!-- Will have to add card logic later -->
      <p class="noCardText">No Card On File</p>

      <button @click="showPortal()" type="submit" class="subBtn" >Update Payment Info</button>
    </Box>

    <Box class="profileBox" :style="`color: ${colorScheme.text};`">
      <p class="boxHeader">Push Notifications</p>
      <p class="box1Text">Allow Push Notifications: 
        <span :style="`color: ${pushEnabled  ? 'green' : 'red'}`">{{DisplayPush(pushEnabled)}}</span>
      </p>
      <br/>
      <div class="settingActions">
      <!-- Add a disable button -->
        <button v-if="!pushEnabled" class="subBtn GreenColor" @click="enablePushNotifications">Enable Push Notifications</button>
        <button v-if="pushEnabled" class="subBtn RedColor" @click="disablePushNotifications">Disable Push Notifications</button>
      </div>
    </Box>

    <Box class="profileBox" :style="`color: ${colorScheme.text};`">
      <p class="boxInfo">Additional Settings Coming Soon</p>
    </Box>

    <div class="horLine" :style="`border-bottom: 1px solid ${colorScheme.backgroundSecondary};`"></div>

    <button @click="logout()" class="logout">Sign Out</button>
    <p class="betaText" :style="`color: ${colorScheme.text};`">Beta: V{{version}}</p>
    <br/>
    <br/>
    <br/>
</div>
</template>

<script setup>
import TopBar from "../components/TopBar.vue"
import Box from "../components/Box.vue"
import CreateNewBtn from "../components/CreateNewBtn.vue"
import { accent1, accent2, light, dark } from "../../helpers/Colors"
import { onBeforeMount, onMounted, ref, toRaw, watch } from "vue"
import api from "../../actors/api"
import { useEmidyStore } from '../../store/emidystore'
import { version } from "../../package.json"
// import { urlBase64ToUint8Array } from "../../helpers/Functions";

const store = useEmidyStore();
let colorScheme = store.dark ? dark : light;
let verified = ref(null);
let pushEnabled = ref(false);


onBeforeMount(() => {
  verified.value = store.verified ? "Yes" : "No";
  pushEnabled.value = store.pushNotificationsEnabled;
})

onMounted(() => {
  // console.log(store.token);
})

let showCheckout = async () => {
  const res = await api.checkout(store.token);
  const body = await res.json()
  window.location.href = body.url
}

let showPortal = async () => {
  const res = await api.update(store.token);
  const body = await res.json()
  window.location.href = body.url
}

let logout = () => {
  store.logout();
}

// Add logic to disable push notifications

let DisplayPush = (pe) => {
  return pe ? "Enabled" : "Disabled";
}

let enablePushNotifications = async () => {
  try{
    let sw = await navigator.serviceWorker.ready;
    await sw.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: "BCI7WNqoy9Jm8YCKN1b1_TALQ5GuLEflcRHvkjP42Efxy87VTa1hvkFU0GdxPwy0fi-omYUhOhlQti4yUoBpCHE"
    }).then(sub => {
      if(store.token && sub){
        api.post("users/update_user_settings", {
          web_push_enabled: true,
          web_push_credentials: JSON.stringify(sub)
        }, store.token)
        .then(res => {
          console.log(res);
          if(res.status >= 400){
            alert("Sorry, Try again later :(")
          }else{
            pushEnabled.value = true;
          }
        })
      }
    });
  }catch(error){
    console.log(error);
  }
}

// Add logic to disable push notifications
let disablePushNotifications = async () => {
  if(store.token){
    api.post("users/update_user_settings", {
      web_push_enabled: false,
      web_push_credentials: ""
    }, store.token)
    .then(res => {
      if(res.status >= 400){
        console.log("Sorry, Try again later :(")
      }else{
        pushEnabled.value = false;
      }
    })
  }

  let sw = await navigator.serviceWorker.ready;
  let getSub = await sw.pushManager.getSubscription();
  if(getSub){
    let unSub = getSub.unsubscribe().then((successful) => {
    })
    .catch((e) => {
      console.log(e);
    });
  }
}


</script>
<style scoped>
.profileName{
  font-weight: bold;
  padding-top: 50px;
  font-size: 1.4rem;
}
.boxHeader{
  margin-top: 0;
  padding-top: 0;
  text-align: left;
  font-weight: bold;
  opacity: .4;
  font-size: 1.2rem;
}
.box1{
  text-align: left;
}
.box1Text{
  font-weight: 600;
  letter-spacing: 1px;
}
.boxInfo{
  font-weight: bold;
  opacity: .4;
  font-size: 1.2rem;
}
.horLine{
  width: 80%;
  margin-left: 10%;
  margin-top: 40px;
  margin-bottom: 40px;
}
.trialText1{
  font-weight: bold;
  text-decoration: underline;
  font-size: 1.2rem;
}
.trialText2{
  text-decoration: line-through;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}
.updatePlanText{
  font-weight: bold;
  padding: 7px;
}
.noCardText{
  font-style: italic;
  font-weight: bold;
}
.logout{
  color: red;
  border: none;
  padding: 10px 100px;
  background: none;
  font-size: 1.1rem;
  letter-spacing: 1px;
  font-weight: bold;
}
.betaText{
  font-size: .6rem;
  margin-bottom: 100px;
  font-weight: bold;
}
.GreenColor{
  color: green;
}
.RedColor{
  color: red;
}
</style>