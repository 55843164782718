import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SignIn from '../views/SignIn.vue'
import SignUp from '../views/SignUp.vue'

import Dash from '../views/Dash.vue'
import CreateTopic from '../views/CreateTopic.vue'
import Topics from '../views/Topics.vue'
import TopicSingle from '../views/TopicSingle.vue'
import CreateLesson from '../views/CreateLesson.vue'
import Lessons from '../views/Lessons.vue'
import LessonsList from '../views/LessonsList.vue'
import LessonSingle from '../views/LessonSingle.vue'
import Resources from '../views/Resources.vue'
import Feedback from '../views/Feedback.vue'
import Profile from '../views/Profile.vue'
import infoSolve from '../views/InfoEmidyImproveLife.vue'
import infoUse from '../views/InfoHowToUseEmidy.vue'
import infoEmidy from '../views/InfoWhatIsEmidy.vue'
import NotFound from '../views/NotFound.vue'
import Success from '../views/Success.vue'
import ErrorPage from '../views/ErrorPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/signin',
    name: 'signin',
    component: SignIn
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp
  },
  {
    path: '/dash',
    name: 'dash',
    component: Dash
  },
  {
    path: '/createtopic',
    name: 'createTopic',
    component: CreateTopic
  },
  {
    path: '/topics',
    name: 'topics',
    component: Topics
  },
  {
    path: '/topics/topic/:id',
    name: 'topicsSingle',
    component: TopicSingle,
  },
  {
    path: '/createlesson',
    name: 'createLsson',
    component: CreateLesson
  },
  {
    path: '/lessons',
    name: 'lessons',
    component: Lessons
  },
  {
    path: '/lessons/list',
    name: 'lessonsList',
    component: LessonsList
  },
  {
    path: '/lessons/lesson',
    name: 'lessonSingle',
    component: LessonSingle
  },
  {
    path: '/resources',
    name: 'resources',
    component: Resources
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: Feedback
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/infosolve',
    name: 'infoSolve',
    component: infoSolve
  },
  {
    path: '/infouse',
    name: 'infoUse',
    component: infoUse
  },
  {
    path: '/infoemidy',
    name: 'infoEmidy',
    component: infoEmidy
  },
  {
    path: '/success',
    name: 'success',
    component: Success
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPage
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: {
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
