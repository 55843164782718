<template>
<div>
  <TopBar :title="thisTopicTitle" :color="thisTopicColor" textColor="#f2f2f2" svgColor="filter: invert(100%) sepia(1%) saturate(2656%) hue-rotate(288deg) brightness(115%) contrast(90%);" @options="confirmEdit = !confirmEdit"></TopBar>
  <div class="customVcHeader" :style="`background-color: ${colorScheme.cardBackground}; color: ${colorScheme.text};`">
    <img :src="leftArrow" alt="move calendar left" class="cIcon cLeft" @click="moveLeft" :style="svgColorScheme"/>
    <p class="currentDate" >{{monthList[currentMonth]}} {{currentYear}}</p>
    <img :src="rightArrow" alt="move calendar right" class="cIcon cRight" @click="moveRight" :style="svgColorScheme"/>
  </div>
  <VDatePicker  
    class="vCalendar"
    ref="calendar"
    borderless
    expanded
    :attributes='[
      {
        // content: "sky-blue",
        highlight: "accent",
        dates: favDates,
        order: 1
      },
      {
        dot: {
          style: {
            backgroundColor: badColor,
          }
        },
        dates: badDates,
        order: 1
      },      
      {
        dot: {
          style: {
            backgroundColor: poorColor,
          }
        },
        dates: poorDates,
        order: 2
      },      
      {
        dot: {
          style: {
            backgroundColor: goodColor,
          }
        },
        dates: goodDates,
        order: 3
      },      
      {
        dot: {
          style: {
            backgroundColor: greatColor,
          }
        },
        dates: greatDates,
        order: 4
      }
    ]'
    :max-date="new Date()"
    v-model="dater"
    :is-dark="checkIsDark"
  /> 

  <Mode v-if="confirmEdit" @close="cancelEdit" >
    <div class="wholeEdit">
      <div class="textEditWrap">
        <label class="editLabel"> Change Color</label>
      </div>
      <div class="colorEdit">
        <div v-for="c in topicColors" :key="c.id" class="singleEditColor" @click="setNewColor(c)" :style="colorEdit == c ? 'border-color: #fff;' : 'border-color: #000;'"><div class="innerColorEdit" :style="`background-color:${c};`"></div></div>
      </div>
      <div class="textEditWrap">
        <label class="editLabel"> Edit Title</label>
      </div>
      <input v-model="titleEdit" class="editInput"/>
      <br/>
      <br/>
      <div class="textEditWrap">
        <label class="editLabel"> Edit Goal</label>
      </div>
      <textarea v-model="descEdit" class="editInput" rows="5"></textarea>
      <div class="editBtnWrap">
        <p class="subBtn ornBtn" @click="cancelEdit">Cancel</p>
        <p class="subBtn greenBtn" @click="saveEdits">Save</p>
      </div>
      <div class="horLine"></div>
      <br/>
      <br/>
      <p class="subBtn redBtn" @click="beginDelete">Delete Topic</p>
      <span class="dngText">*This will delete all Lessons within this Topic!</span>
    </div>
  </Mode>
  <Mode v-if="deleteConfirm" @close="cancelEdit">
    <p class="reallyText">Really Delete Topic & Lessons?</p>
    <div class="editBtnWrap">
      <p class="subBtn redBtn" @click="deleteTopic">Yes</p>
      <p class="subBtn greenBtn" @click="cancelEdit">No</p>
    </div>
  </Mode>

  <CreateNewBtn text="Create New Lesson" :color="thisTopicColor" @create="router.push({path: '/createlesson', query: {topicID: topicID}})"></CreateNewBtn>

  <Box class="goalBox" :style="`color: ${colorScheme.text};`">
      <p class="sectionHeader" >Goal/Vision</p>
      <p v-text="thisTopicDesc" class="goalDesc"></p>
  </Box>

  <Box v-if="hasLessons && lessonAvgs && !loading" :style="`color: ${colorScheme.text};`">
    <p class="sectionHeader">Analytics</p>
    <div class="allLessonsAvgWrap">
      <p class="smallText glanceText">All Lessons Average</p>
      <div class="lessonAvg"><p :style="`border: 13px solid ${getColor(lessonAvg)}; color: ${getColor(lessonAvg)};`">{{lessonAvg}}</p></div>
    </div>
    <p class="glanceText lessonNumText">Number of Lessons: <span class="lessonNumInner">{{lessonNum}}</span></p>
    <div class="horLine"></div>
    <p class="glanceText">At A Glance</p>
    <div v-if="lessonAvgs" class="glanceChart">
      <div class="chartRow">
        <p class="chartText">8 - 10</p>
        <div class="chartBar" :style="`background-color: ${greatColor}; width: ${lessonAvgs.great}%`"></div>
      </div>
      <div class="chartRow">
        <p class="chartText">6 - 7</p>
        <div class="chartBar" :style="`background-color: ${goodColor}; width: ${lessonAvgs.good}%`"></div>
      </div>
      <div class="chartRow">
        <p class="chartText">4 - 5</p>
        <div class="chartBar" :style="`background-color: ${poorColor}; width: ${lessonAvgs.poor}%`"></div>
      </div>
       <div class="chartRow">
        <p class="chartText">0 - 3</p>
        <div class="chartBar" :style="`background-color: ${badColor}; width: ${lessonAvgs.bad}%`"></div>
      </div>
    </div>
  </Box>

  <Box  v-if="hasLessons && !loading" :style="`color: ${colorScheme.text};`">
    <p class="sectionHeader">Best/Worst Days</p>
    <div v-if="bestAndWorstDays">
      <p class="glanceText">Best Day(s)</p>
      <LessonBox v-for="l in bestAndWorstDays.best" :key="l._id" :l="l"></LessonBox>
    </div>
    <div v-if="bestAndWorstDays">
      <p class="glanceText">Worst Day(s)</p>
      <LessonBox v-for="l in bestAndWorstDays.worst" :key="l._id" :l="l"></LessonBox>
    </div>
  </Box>


  <div v-if="!loading && lessons.length == 0">
    <p class="info" :style="`color: ${colorScheme.text};`">Create Lessons to see analytics</p>
  </div>
  
  <div v-if="loading">
    <p class="info" :style="`color: ${colorScheme.text};`">Loading...</p>
  </div>

  <div style="width: 100%; height: 7vh;"></div>
</div>  
</template>
<script setup>
import { onBeforeUnmount, onMounted, ref, watch, toRaw, onBeforeMount, computed } from "vue";
import { dark, light, accent1, accent2, badColor, poorColor, goodColor, greatColor } from "../../helpers/Colors";
import { getColor } from "../../helpers/Functions";
import CreateNewBtn from "../components/CreateNewBtn.vue"
import leftArrow from "@/assets/icons/left.svg"
import rightArrow from "@/assets/icons/right.svg"
import TopBar from "../components/TopBar.vue"
import Box from "../components/Box.vue"
import Mode from "../components/Mode.vue"
import LessonBox from "../components/LessonBox.vue"
import api from "../../actors/api";
import { useEmidyStore } from '../../store/emidystore';
import { useRoute } from 'vue-router';
import router from "@/router";
const route = useRoute();
const store = useEmidyStore();

let colorScheme = store.dark ? dark : light;
let checkIsDark = store.dark;
let svgColorScheme = store.dark ? 'filter: invert(100%) sepia(1%) saturate(2656%) hue-rotate(288deg) brightness(115%) contrast(90%);' : 'filter: invert(0%) sepia(6%) saturate(7500%) hue-rotate(12deg) brightness(104%) contrast(106%);';


let arrows = ref(null);
let calendar = ref(null);
const dater = ref(null);

let topicColors = [ "#ab93be", "#7052ab", "#005085", "#2f95dc", "#8997b0", "#a2a2af", "#45a34e", "#871313", "#cc5500", "#f88379", "#98abb0", "#292e38"]
let monthList = ["January","February","March","April","May","June","July","August","September","October","November","December"];
let currentMonth = ref(new Date().getMonth());
let currentYear = ref(new Date().getFullYear());

let topicID = ref(null);
let loading = ref(true);
let confirmEdit = ref(false);
let deleteConfirm = ref(false);

let thisTopic = ref(null);
let thisTopicTitle = ref(null);
let thisTopicColor = ref(null);
let thisTopicDesc = ref(null);
let lessons = ref(null);
let lessonAvg = ref(null);
let lessonAvgs = ref(null);
let lessonNum = ref(null);
let lessonCount = ref(null);
let bestAndWorstDays = ref(null);

let titleEdit = ref(null);
let descEdit = ref(null);
let colorEdit = ref(null);

let favDates = ref([]);
let badDates = ref([]);
let poorDates = ref([]);
let goodDates = ref([]);
let greatDates = ref([]);



let testAlert = () => {
  alert("HI");
}



onBeforeMount(() => {
  topicID.value = route.params.id;
  // console.log(route.query);

  if(store.forceUpdate){
    api.get(`emidy/topic/${route.params.id}`, store.token)
    .then(res => {
      if(res.status < 400){
        res.json().then((data) => {
          // console.log(data);

          thisTopic.value = data;
          thisTopicTitle.value = data.title;
          thisTopicColor.value = data.color;
          thisTopicDesc.value = data.description;
          lessonNum.value = data.lessonNum;
          lessonAvg.value = data.lessonAvg;

          titleEdit.value = data.title;
          descEdit.value = data.description;
          colorEdit.value = data.color;
        })
      }
    });
    store.toggleForceUpdate();
  }else{
    thisTopicTitle.value = route.query.title;
    thisTopicColor.value = route.query.color;
    thisTopicDesc.value = route.query.description;
    lessonNum.value = route.query.lessonNum;
    lessonAvg.value = route.query.lessonAvg;

    titleEdit.value = route.query.title;
    descEdit.value = route.query.description;
    colorEdit.value = route.query.color;
    
    api.get(`emidy/topic/${route.params.id}`, store.token)
    .then(res => {
      if(res.status < 400){
        res.json().then((data) => {
          thisTopic.value = data;
        })
      }
    });
  }
})

onMounted(() => {
  setTimeout(() => {
    arrows.value = document.querySelectorAll(".vc-arrow");

    arrows.value.forEach(a => {
      a.addEventListener("click", () => {alert("Arrow Clicked")});
    })
  }, 100);

  fetchData();
})

onBeforeUnmount(() => {
  arrows.value.forEach(a => {
    a.removeEventListener("click", () => {alert("Arrow Clicked")});
  })
})


const fetchData = () => {
  api.get(`emidy/lessons/topic/${topicID.value}`, store.token)
  .then(res => {
    if(res.status >= 400){
      alert("Sorry, try again later :(")
    }else{
      res.json().then(data => {
        lessons.value = data;
        // console.log(data);

        // Sets up the best/worst emotion level for Best and Worst Days
        let bestDayNum = 0;
        let worstDayNum = 10;

        // For calendar markers
        let allMarkers = {};

        // Setting up lesson avg
        let bad = 0;
        let poor = 0;
        let good = 0;
        let great = 0;
        data.forEach((l) => { // Do as much as we can in a single loop to save resources
          if(l.emotion > bestDayNum){
            bestDayNum = l.emotion;
          }
          if(l.emotion < worstDayNum){
            worstDayNum = l.emotion;
          }

          // let splitData = l.created.split("T")[0]; // Every route will use this date for markers
           // Every route will use this date for markers
          // function parseISOString(s) {
          //   var b = s.split(/\D+/);
          //   return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
          // }


          // var s = l.displayDate;
          // var parseDate = parseISOString(s);
          // a workaround that adds the time offset to the time created to make it more consistant in the calendar.
          // Might have to add logic for daylight savings or something.
          var splitData = new Date(l.displayDate + (200 * 60 * 1000))

          if(l.favorite){
            favDates.value.push(splitData);
          }

          if(l.emotion <= 3){
            bad++;
            // if(allMarkers[splitData] && allMarkers[splitData]["dots"]){
            //   allMarkers[splitData]["dots"].push({color: Colors.badColor})
            // }else{
            //   allMarkers[splitData] = {selected: l.favorite, marked: true, dots: [{color: Colors.badColor}], selectedColor: Colors.accent1O};
            // }
            badDates.value.push(new Date(splitData));
          }else if(l.emotion == 4 ||l.emotion == 5){
            poor++;
            // if(allMarkers[splitData] && allMarkers[splitData]["dots"]){
            //   allMarkers[splitData]["dots"].push({color: Colors.poorColor, selectedDotColor: Colors.poorColor})
            // }else{
            //   allMarkers[splitData] = {selected: l.favorite, marked: true, dots: [{color: Colors.poorColor}], selectedColor: Colors.accent1O};
            // }
            poorDates.value.push(new Date(splitData));
          }else if(l.emotion == 6 || l.emotion == 7){
            good++;
            // if(allMarkers[splitData] && allMarkers[splitData]["dots"]){
            //   allMarkers[splitData]["dots"].push({color: Colors.goodColor, selectedDotColor: Colors.goodColor})
            // }else{
            //   allMarkers[splitData] = {selected: l.favorite, marked: true, dots: [{color: Colors.goodColor}], selectedColor: Colors.accent1O};
            // }
            goodDates.value.push(new Date(splitData));
          }else{
            great++;
            // if(allMarkers[splitData] && allMarkers[splitData]["dots"]){
            //   allMarkers[splitData]["dots"].push({color: Colors.greatColor})
            // }else{
            //   allMarkers[splitData] = {selected: l.favorite, marked: true, dots: [{color: Colors.greatColor}], selectedColor: Colors.accent1O};
            // }
            greatDates.value.push(new Date(splitData));
          }
        })


        lessonCount.value = {
          bad: bad,
          poor: poor,
          good: good,
          great: great,
        }


        if(bad == 0){
          bad = 0.02;
        }
        if(poor == 0){
          poor = 0.02;
        }
        if(good == 0){
          good = 0.02;
        }
        if(great == 0){
          great = 0.02;
        }

        lessonAvgs.value = {
          bad: (bad/data.length) * 100,
          poor: (poor/data.length) * 100,
          good: (good/data.length) * 100,
          great: (great/data.length) * 100
        }



        // setMarkedDays(allMarkers);

        // Gets the best and worst days. Only selects the lesson(s) with the highest/lowest recorded emotion
        let selectedBestCount = 0;
        let bestDaysArr = data.filter((l) => {
          if (l.emotion == bestDayNum && selectedBestCount < 3) {
            selectedBestCount++;
            return true;
          }
          return false;
        })

        let selectedWorstCount = 0;
        let worstDaysArr = data.filter((l) => {
          if (l.emotion == worstDayNum && selectedWorstCount < 3) {
            selectedWorstCount++;
            return true;
          }
          return false;
        })

        bestAndWorstDays.value = {best: bestDaysArr, worst: worstDaysArr};

        loading.value = false;
        // resetUpdatedLesson();
      })
    }
  })
}



let setNewColor = (c) => {
  colorEdit.value = c;
}

let beginDelete = () => {
  confirmEdit.value = false;
  deleteConfirm.value = true;
}

let cancelEdit = () => {
  colorEdit.value = thisTopicColor.value;
  titleEdit.value = thisTopicTitle.value;
  descEdit.value = thisTopicDesc.value;
  confirmEdit.value = false;
  deleteConfirm.value = false;
}

let saveEdits = () => {
  api.put("emidy/topic", {
    _id: topicID.value,
    title: titleEdit.value,
    description: descEdit.value,
    color: colorEdit.value,
  }, store.token)
  .then(res => {
    if(res.status == 200){
      router.back();
    }else{
      console.log(res);
      alert("Sorry, Try again later :(");
    }
  })
}

let deleteTopic = () => {
  api.get(`emidy/delete_topic/${topicID.value}`, store.token)
  .then(res => {
    if(res.status >= 400){
      alert("Sorry, Try again later :(")
    }else{
      router.back();
    }
  })
}

watch(dater, () => {dayClick()})
// watch(calendar, () => {dayClick()})

let dayClick = () => {
  router.push({path: "/lessons/list", query: {date: dater.value.toISOString(), topicID: topicID.value}})
}




let moveMonth = (move) => {
  if(move == 1){
    if(currentMonth.value + 1 < 12){
      currentMonth.value++;
    }else{
      currentYear.value++;
      currentMonth.value = 0;
    }
  }else if(move == -1){
    if(currentMonth.value - 1 >= 0){
      currentMonth.value = currentMonth.value - 1;
    }else{
      currentYear.value = currentYear.value - 1;
      currentMonth.value = 11;
    }
  }
}

async function moveLeft() {
  moveMonth(-1);
  await calendar.value.moveBy(-1);
}
async function moveRight() {
  moveMonth(1);
  await calendar.value.moveBy(1);
}



let hasLessons = computed(() => {
  if(lessons.value){
    if(lessons.value.length > 0){
      return true;
    }
  }
  return false;
})

</script>
<style scoped>
:deep(.vCalendar) .vc-weekday-1, :deep(.vCalendar) .vc-weekday-7 {
  color: #6366f1;
}
:deep(.vCalendar) .vc-header {
  display: none;
}

:deep(.vCalendar) .vc-sky-blue {
  --vc-accent-50: #f0f9ff;
  --vc-accent-100: #e0f2fe;
  --vc-accent-200: #bae6fd;
  --vc-accent-300: #7dd3fc;
  --vc-accent-400: #38bdf8;
  --vc-accent-500: #0ea5e9;
  --vc-accent-600: #0284c7;
  --vc-accent-700: #0369a1;
  --vc-accent-800: #075985;
  --vc-accent-900: #0c4a6e;
}
:deep(.vCalendar) .vc-accent {
  --vc-accent-50: #ab93be;
  --vc-accent-100: #ab93be;
  --vc-accent-200: #ab93be;
  --vc-accent-300: #ab93be;
  --vc-accent-400: #ab93be;
  --vc-accent-500: #ab93be;
  --vc-accent-600: #ab93be;
  --vc-accent-700: #ab93be;
  --vc-accent-800: #ab93be;
  --vc-accent-900: #ab93be;
}

.customVcHeader{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cIcon{
  width: 90px;
  padding-left: 32px;
  padding-right: 32px;
}
.currentDate{
  font-weight: bold;
}
.info{
  font-size: 18px;
  font-weight: bold;
}

.sectionHeader{
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  margin: 0;
  padding: 10px;
}
.goalDesc{
  font-size: 18px;
  padding: 0 20px;
}
.lessonAvg{
  display: flex;
  justify-content: center;
  width: 100%;
}
.lessonAvg p{
  font-size: 50px;
  margin-bottom: 0;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 80px; 
  width: 120px;
  height: 120px;
}
.lessonNumText{
  text-align: left;
  padding: 15px !important; 
}
.horLine{
  width: 80%;
  margin-left: 10%;
  border-bottom: 1px solid #777;
}
.glanceText{
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 0;
  padding: 0;
  font-size: 15px;
}
.chartText{
  padding: 0;
  margin: 0;
  margin-top: 25px;
  text-align: left;
  font-size: 15px;
  color: #666;
}
.chartBar{
  height: 13px;
  border-radius: 10px;
}

.wholeEdit{
  padding-bottom: 20px;
}
.textEditWrap{
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
  padding-left: 20px;
  padding-top: 20px;

}
.editLabel{
  color: #f2f2f2;
  width: 100%;
  text-align: left;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
.editInput{
  width: 90%;
  padding: 10px;
}
.colorEdit{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.singleEditColor{
  margin: 7px;
  width: min-content;
  border-width: 2px;
  border-style: solid;
  border-radius: 40px;
}
.innerColorEdit{
  height: 35px;
  width: 35px;
  border-radius: 40px;
}
.editBtnWrap{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.subBtn{
  color: white;
  padding: 10px 20px;
  font-weight: bold;;
}
.dngText{
  color: red;
  font-size: 13px;
  padding: 0;
  margin: 0;
}
.greenBtn{
  color: green;
}
.ornBtn{
  color: orange;
}
.redBtn{
  color: red;
}
.reallyText{
  color: white;
}
</style>