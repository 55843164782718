<template>
<div>
  <div v-if="menuOpen">
    <LeftNav @close="closeMenu()"/>
  </div>
  <div class="space"></div>
  <div class="wholeTopBar" :style="`background-color: ${props.color ? props.color : colorScheme.background};`">
    <div class="topBarItem leftItem" :style="props.svgColor ? props.svgColor : svgColorScheme">
      <img v-if="showBack" src="../assets/icons/round-left.svg" alt="Back" class="navSvg backArrow" @click="goBack()">
      <img v-if="showMenuIcon" src="../assets/icons/grid.svg" alt="Menu" class="navSvg menuGrid" @click="openMenu()"/>
    </div>
    <div class="topBarItem">
      <p class="tabText" v-text="props.title || tabText" :style="`color: ${props.textColor ? props.textColor : colorScheme.text};`"></p>
    </div>
    <div class="topBarItem rightItem" :style="props.svgColor ? props.svgColor : svgColorScheme">
      <img v-if="showPro" src="../assets/icons/setting.svg" alt="Profile" class="navSvg profileSvg" @click="showProfile()">
      <img v-if="showOptions" src="../assets/icons/options.svg" alt="Options" class="navSvg optionSvg" @click="$emit('options')">
    </div>
  </div>
</div>
</template>
<script setup>
import { useEmidyStore } from '../../store/emidystore';
import { onBeforeUnmount, onMounted, ref, watch, defineProps, toRaw } from 'vue'
import router from '@/router';
import { truncate } from "../../helpers/Functions";
import { useRoute } from 'vue-router';
import { light, dark } from "../../helpers/Colors";
import LeftNav from "./LeftNav.vue"

const props = defineProps(["title", "color", "textColor", "svgColor"]);

const store = useEmidyStore();
const route = useRoute();


let colorScheme = store.dark ? dark : light;

let svgColorScheme = store.dark ? 'filter: invert(100%) sepia(1%) saturate(2656%) hue-rotate(288deg) brightness(115%) contrast(90%);' : 'filter: invert(0%) sepia(6%) saturate(7500%) hue-rotate(12deg) brightness(104%) contrast(106%);';

const tabText = ref(null);
const tabColor = ref(null);
const showBack = ref(false);
const showPro = ref(true);
const showOptions = ref(false);
const showMenuIcon = ref(false);
const menuOpen = ref(false);

watch(route, () => {activeTab(route.path);});
watch(props, () => {setInfo()});

onMounted(() => {
  activeTab(route.path);
})


let activeTab = (path) => {
  // Resets
  showMenuIcon.value = false;
  showBack.value = false;
  showPro.value = false;

  if(path == ("/dash")){
    showMenuIcon.value = true;
    showPro.value = true;
    tabText.value = "Dashboard";
  }else if(path == ("/createtopic")){
    showBack.value = true;
    tabText.value = "Create Topic";
  }else if(path == ("/topics")){
    showMenuIcon.value = true;
    showPro.value = true;
    tabText.value = "Topics";
  }else if(path.includes("/topics/topic")){
    showBack.value = true;
    showOptions.value = true;
  }else if(path == ("/createlesson")){
    showBack.value = true;
    tabText.value = "Create Lesson";
  }else if(path == ("/lessons")){
    showMenuIcon.value = true;
    showPro.value = true;
    tabText.value = "Lessons";
  }else if(path == ("/lessons/list")){
    showBack.value = true;
  }else if(path == ("/lessons/lesson")){
    showBack.value = true;
    showOptions.value = true;
  }else if(path == ("/resources")){
    showMenuIcon.value = true;
    showPro.value = true;
    tabText.value = "Resources";
  }else if(path == ("/feedback")){
    showMenuIcon.value = true;
    showPro.value = true;
    tabText.value = "Feedback";
  }else if(path == ("/profile")){
    showBack.value = true;
    tabText.value = "Profile";
  }else{   
    showBack.value = true;
  }
}

let closeMenu = () => {
  menuOpen.value = false;
  document.documentElement.style.overflow = "visible";
}
let openMenu = () => {
  menuOpen.value = true;
  document.documentElement.style.overflow = "hidden";
}

let setInfo = () => {
  tabText.value = props.title ? `${truncate(props.title, 14)}`: "";
  tabColor.value = props.color ? props.color: "";
}


let showProfile = () => {
  router.push("/profile");
}

let goBack = () => {
  router.back();
}

</script>
<style scoped>
.space{
  margin-top: 9vh;
}
.wholeTopBar{
  position: fixed;
  top: 0;
  width: 100vw;
  height: 8vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  z-index: 2;
}
.topBarItem{
  width: 33%;
}
.navSvg{
  height: 25px;
  width: min-content;
}
.leftItem{
  text-align: left;
  display: flex;
  justify-content: left;
  align-items: center;
}
.backArrow{
  position: absolute;
  left: 0;
  margin-left: 3px;
}
.menuGrid{
  margin: 10px;
}
.rightItem{
  text-align: right;
  display: flex;
  justify-content: right;
  align-items: center;
}
.rightItem:active{
  opacity: .5;
}
.profileSvg, .optionSvg{
  margin-right: 5px;


  /* Black */
  /* filter: invert(0%) sepia(6%) saturate(7500%) hue-rotate(12deg) brightness(104%) contrast(106%); */
  /* Grey  */
  /* filter: invert(45%) sepia(88%) saturate(0%) hue-rotate(233deg) brightness(88%) contrast(101%); */
  /* Grey-ish  */
  /* filter: invert(17%) sepia(0%) saturate(0%) hue-rotate(99deg) brightness(97%) contrast(89%); */
  /* f2 */
  /* filter: invert(100%) sepia(1%) saturate(2656%) hue-rotate(288deg) brightness(115%) contrast(90%); */

}
.tabText{
  font-family: "OpenSansBold";
  font-weight: 600;
  font-size: .9rem;
  letter-spacing: 1px;
  color: #333;
}
</style>