<template>
<div>
  <TopBar title="What does Emidy Solve?"></TopBar>
  <Box>
  <div class="infoHeader">
    <p>How Will My Life Improve?</p>
  </div>

  <div :style="`color: ${colorScheme.text};`" class="textWrap">
    <p class="text big">We know understanding ourselves is hard. That's why we've developed Emidy to assist in building our emotional intelligence and achieving our personal goals.</p>
    <p class="text sma">It's important to  note that everyone learns and develops differently, and each person needs to understand what works best for them.</p>
    <p class="text sma">A big issue with existing software is that most systems don't work for everyone, or they only work for a while but end up not being impactful in the long run.</p>
    <p class="text sma">Many people become frustrated when they try their best to develop themselves but end up not getting anywhere.</p>
    <p class="text med">This leads to lowered self confidence and a belief that they won't ever be able to succeed.</p>
    <p class="text big">That's why we came at emotional and mental development from a different approach. We've stuck to a simple ideology, that everyone is different and requires individual guidance.</p>
    <p class="text sma">With Emidy, the more you put in, the more you will get out. The more focused you set up your Topics, the easier it is to track your progress towards your goal.</p>
    <p class="text sma">Emidy provides personalized guidance because YOU are the teacher. You provide your daily Lessons and reflect on them in order to move you closer to your end goal.</p>
    <p class="text sma">This means that you can reflect on why you did an action, and within what scenario, and in what conp. We offer you the toolkit to go back in time and explore how you could have solved that problem differently.</p>
    <p class="text med">Also, you will be able to see continuous progress towards your goals as you log your Lessons. Emidy provides analytics to dig deep into your Lessons and see how far you've come.</p>
    <p class="text med">In conclusion, Emidy provides you with the tools to create your own roadmap, and lets you track your progress along the way. You don't align yourself with the ideals of someone else, but instead with what you discover about yourself.</p>
  </div>
  <br/>
  <br/>
  <br/>
  </Box>
</div>
</template>
<script setup>
import Box from "../components/Box.vue";
import TopBar from "../components/TopBar.vue"
import { light, dark } from "../../helpers/Colors";
import { useEmidyStore } from '../../store/emidystore';
import { onMounted } from "vue";


const store = useEmidyStore();
let colorScheme = store.dark ? dark : light;


onMounted(() => {
  window.scrollTo(0, 0);
})


</script>
<style scoped>
.infoHeader{
  background-image: url("../assets/images/banner.jpg");
  color: white;
  padding: 1px;
  border-radius: 10px;
  font-weight: bold;
}
.textWrap{
  text-align: left;
  padding: 15px;
  line-height: 30px;
  font-weight: 500;
}
.big{
  font-size: 1.1rem;
  font-weight: 600;
}
.med{
  font-size: 1.05rem;
}
.sma{
  font-size: 1rem;
}
</style>