<template>
  <div class="home" :style="`background-color: #d0c3db`">
    <img alt="Vue logo" src="../assets/logo.png" class="logo">

  </div>
</template>

<script setup>
import { ref , onMounted, onBeforeMount} from "vue"
import Mode from "@/components/Mode.vue";
import ScrollView from "@/components/ScrollView.vue";
import router from "@/router";
import { useEmidyStore } from '../../store/emidystore';
import { accent3 } from '../../helpers/Colors';
// @ is an alias to /src

const store = useEmidyStore();

onBeforeMount(() => {
  const user = localStorage.getItem('emidy_token');

  setTimeout(() => {

    if(user && store.token && store.email && store.name){
        router.replace("/dash");
    }else{
      router.replace("/signin");
    }
  }, 1000);
})

</script>
<style scoped>
.home{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.logo{
  width: 50vw;
}
</style>