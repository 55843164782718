<template>
  <div>
    <TopBar></TopBar>
    <CreateNewBtn text="Create New Topic" :color="accent1" @create="createNew"></CreateNewBtn>
    <div class="horLine"></div>

    <!-- Make pinned Topics section -->

    <div v-if="topics && !loading" class="allTopics">
      <div v-for="t in topics" :key="t.id" class="topicWrapper" :style="`background-color: ${t.color};`" @click="router.push({path: `/topics/topic/${t._id}`, query: {id: t._id, title: t.title, color: t.color, description: t.description, lessonAvg: t.lessonAvg, lessonNum: t.lessonNum}})">
        <p class="topicTitle">{{t.title}}</p>
        <p class="topicInfo"># of Lessons: {{t.lessonNum}}</p>
        <p class="topicInfo">Average Day: {{t.lessonAvg}}</p>
        <p class="topicDesc">{{truncate(t.description, 35)}}</p>
      </div>
    </div>
    
    <div v-if="!topics && !loading">
      <p class="info" :style="`color: ${colorScheme.text};`">Create A Topic First</p>
    </div>

    <div v-if="loading">
      <p class="info" :style="`color: ${colorScheme.text};`">Loading...</p>
    </div>

  </div>
</template>
<script setup>
import TopBar from "../components/TopBar.vue"
import CreateNewBtn from "../components/CreateNewBtn.vue"
import { accent1, light, dark } from "../../helpers/Colors";
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";
import api from "../../actors/api";
import { useEmidyStore } from '../../store/emidystore';
import { truncate } from "../../helpers/Functions";
import router from "@/router";


const store = useEmidyStore();

let colorScheme = store.dark ? dark : light;

const loading = ref(true);
const topics = ref(null);

let createNew = () => {
  router.push("/createtopic");
}


onBeforeMount(() => {
  fetchData();
})

let fetchData = () => {
  if(store.token){
    api.get("emidy/topics", store.token)
    .then(res => {
      if(res.status >= 400){
        alert("Sorry, Try again later :(")
      }else{
        res.json().then(data => {
          // console.log(data);

          if(data.length > 0){
            topics.value = data;
          }
          loading.value = false;
        })
      }
    })
  }
}


</script>
<style scoped>
  .horLine{
    background-color: rgba(0,0,0,.1);
    height: 1px;
    width: 80%;
    margin-left: 10%;
    margin-bottom: 7%;
    margin-top: 5%;
  }
  .allTopics{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 35%;
  }
  .topicWrapper{
    width: 45%;
    border-radius: 20px;
    text-align: left;
    padding: 20px;
    margin-bottom: 15px;
    max-height: 170px;
  }
  .info{
    font-size: 18px;
    font-weight: bold;
  }
  .topicTitle{
    color: #f2f2f2;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;;
  }
  .topicInfo{
    color: #f2f2f2;
    font-weight: bold;
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
  .topicDesc{
    color: #f2f2f2;
    font-weight: bold;
    font-size: 12px;
    padding: 0;
    margin: 0;
    margin-top: 15px;
  }
</style>