<template>
<div style="overflow: hidden;">
<div class="container">
  
  <Box class="box" style="background-color: '#f9f9f9'">
    <div style="styles.imgWrapper">
      <img alt="Vue logo" src="../assets/logo.png" class="signuplogo">
      <div class="betaWrapper">
        <p class="beta">Beta: V{{version}}</p>
      </div>
    </div>
    <h1 class="welcome">Sign In <span class="flavor">Welcome Back :)</span></h1>

    <p style="color: red; textAlign: 'center'; font-size: 12px;" @click="removeError()">{{errorText ? `${errorText} (x)` : '&nbsp;'}}</p>
    <div v-if="loading" style="color: 'grey'; textAlign: 'center'">Loading...</div>
    <div class="horLine"></div>

    <input class="inputStyles" placeholder='Email' placeholderTextColor="#888" v-model="email" />
    <br/>
    <br/>
    <input type="password" class="inputStyles" placeholder='Password' placeholderTextColor="#888" v-model="password" />
    <br/>
    <br/>
    <br/>
    <button class="submitBtn" @click="loginSubmit()">Log In</button>
    <br/>
    <br/>
    <div class="horLine"></div>
    <p @click="goToSignUp()" class="switchBtn" >Need an account? Sign up now!</p>
  </Box>
  <Box class="box2">
    <img alt="Important Info" src="../assets/icons/bulb.svg" class="infoSvg">
    <p>To Download: 1. Click the Share or Option button (<img alt="Share Button Example" src="../assets/icons/share.png" style="width: 13px;"> or <img alt="Options Button Example" src="../assets/icons/dots.svg" style="width: 2px"> ) <br/>2. Add to Homescreen. Thats it!</p>
  </Box>
</div>
</div>
</template>
<script setup>
import Box from "@/components/Box.vue";
import { useEmidyStore } from '../../store/emidystore';
import { onBeforeUnmount, onMounted, ref } from "vue";
import { version } from "../../package.json"
import api from "../../actors/api";
import router from "@/router";

const store = useEmidyStore();

const errorText = ref(null);
const loading = ref(false);

const email = ref(null);
const password = ref(null);

onMounted(() => {
  document.querySelector("body").style.overflow = "hidden";
  document.querySelector("html").style.overflow = "hidden";
})

onBeforeUnmount(() => {
  document.querySelector("body").style.overflow = "visible";
  document.querySelector("html").style.overflow = "visible";
})

let removeError = () => {
  errorText.value = null;
}

let loginSubmit = () => {
  signin();
}

let goToSignUp = () => {
  router.push("/signup");
}

let signin = () => {
  const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);    
  errorText.value = null;

  if(!email.value || !password.value){
    errorText.value = "All Fields Are Required";
  }
  else if(!emailRegex.test(email.value)){
    errorText.value = "Enter A Valid Email";
  }
  else if(password.value.length < 6 || password.value.length > 16){
    errorText.value = "Password 6 - 16 Characters";
  }
  else{
    api.login({email: email.value, password: password.value})
    .then(res => {
      if(res.status > 299){
        errorText.value = "No Account Found";
      }else{
        res.json().then(data => {
          let tempUsr = {
            name: data.userInfo.name,
            email: data.userInfo.email,
            verified: data.userInfo.verified,
            token: data.token
          }
          store.login(tempUsr);
        })
      }
    })
  }
}



</script>
<style scoped>
  .container{
    margin: 0;
    background-color: #e8eafc; 
    height: 100vh;
    padding: 10px;
  }
  .imgWrapper{
    background-color: 'rgba(0,0,0,0)';
    margin: 20px;
    width: 50px;
  }
  .box, .box2{
    background-color: #f9f9f9 !important;
  }
  .infoSvg{
    width: 25px;
  }
  .box2{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0; 
    padding: 1px 1px; 
    font-weight: bold; 
    font-size: .7rem;
  }
  .signuplogo{
    width: 70px;
  }
  .betaWrapper{
    background-color: #e8eafc;
    border-radius: 20px;
    padding: 0 1px;
    margin-top: 10px;
    width: 20%;
    margin-left: 40%;
    margin-bottom: 10px;
  }
  .beta{
    font-size: 9px;
    font-weight: bold;
    color: #666;
  }
  .welcome{
    font-family: "OpenSansEB";
    font-size: 1.7rem;
    font-weight: bold;
    text-align: left;
  }
  .flavor{
    font-size: 12px;
    color: rgba(0,0,0,.5);
    font-weight: 100;
    font-family: "OpenSans";
  }
  .horLine{
    background-color: rgba(0,0,0,.1);
    height: 1px;
    width: 94%;
    margin-left: 3%;
    margin-bottom: 3%;
  }
  .inputStyles{
    width: 90%;
    padding: 7px;
    border-radius: 5px;
    border-width: 1px;
    border-width: rgbe(0,0,0,.5);
  }
  .submitBtn{
    width: 100%;
    border: none;
    background-color: #ab93be;
    border-radius: 3px;
    font-size: 15px;
    padding: 10px;
    color: white;
    font-weight: bold;
  }
  .switchBtn{
    /* padding-top: 30px; 
    padding-bottom: 10px; */
    font-size: 13px;
    text-align: left;
    color: #2f95dc;
  }
</style>